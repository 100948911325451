export const Constants = {
    // environment: "dev",    // 开发环境地址
    // environment: "test", // 测试环境地址
    environment: "prod", // 生产环境地址
    wechat_auth_url: "https://open.weixin.qq.com/connect/qrconnect?appid={appid}&redirect_uri={redirect_uri}&response_type=code&scope=snsapi_login&state=wechat#wechat_redirect",
    env: {
        dev: {
            root_uri: "http://a.imamemo.com",
            // root_uri: "http://a.imamemo.com",
            appid: "wx68253f09a6ce5ff7",

            appleURL: 'https://local.bsxan.com',
            baseURL: 'http://localhost:8085',
        },
        test: {
            root_uri: "http://a.imamemo.com",
            appid: "wx68253f09a6ce5ff7",

            // 测试环境地址
            baseURL: 'http://a.imamemo.com/serve',
            appleURL: 'https://a.imamemo.com',
        },
        prod: {
            root_uri: "https://www.imamemo.com",
            appid: "wx3cdf9671d9433fc4",

            // 生产环境地址
            baseURL: 'https://ca237.imamemo.com/serve',
            appleURL: 'https://ca237.imamemo.com/serve',
        },

    }
}
