<template>
    <a-modal width=100% :bodyStyle="{ height: 'calc(100vh)', overflowY: 'auto', background: 'black'}"
             centered cancelText :visible="visibleInfo" :footer="null" :closable='closable'
             @cancel="handleCancel" wrapClassName="detail-modal-wrap"
             style="background-color: black">
        <div
                class="detail-box"
        >
            <div class="container">

                <div class="container-c">
                    <div class="container-c-left">
                        <div class="item-list" v-if="datas.length > 0">

                            <div class="item-list-imgbox" v-if="currPicture.pictureUrl">
                                <br>
                                <div class="cross" @click="handleCancel()">
                                    <img class="cross-img" src="../assets/cross.png" height="35" width="35"/>
                                </div>
                                <div class="plus" @click="imgbig()">
                                    <img class="plus-img" src="../assets/plus.png" height="15" width="15"/></div>
                                <div class="minus" @click="imgsmall()">
                                    <img class="minus-img" src="../assets/minus.png" height="16" width="16"/>
                                </div>
                                <img class="item-list-img-last" v-show="currPictureIndex !== 0"
                                     @click="imgLast()" src="../assets/left.png"/>
                                <div class="item-list-imgbox-center">
                                    <img class="item-list-img-info"  @load="onload" :src="currPicture.pictureUrl"/>
                                </div>
                                <img class="item-list-img-next" v-show="currPictureIndex !== datas.length - 1"
                                     @click="imgNext()" src="../assets/right.png"/>
                            </div>
                        </div>
                        <div class="item-list" v-else>
                          <div class="item-list-imgbox">
                            <div class="item-list-imgbox-center">
                            <div class="list-loading">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                          </div>
                         </div>
                        </div>
                    </div>
                    <div class="container-c-right">
                        <div class="container-right-box">
                            <div class="container-right-box-top">
                                <div class="item-title">
                                    <!-- <div class="item-list-title">{{ currPicture.entryTitle }}
                                    </div> -->
                                    <a-popover placement="bottomLeft">
                                         <template #content>
                                            <div class="searchTitle">{{$t('searchTerm')}}</div>
                                              <img class="searchImg" src="../assets/guge.png" @click="goGuge(currPicture.entryTitle)" height="21.5" width="26"/>
                                              <img class="searchImg" src="../assets/weiji.png" @click="goWeiji(currPicture.entryTitle)" height="26" width="26"/>
                                         </template>
                                         <div class="item-list-title" @click="goToInDetail(currPicture.entryId,currPicture.entryTitle,currPicture.pictureId)">{{currPicture.entryTitle}}</div>
                                    </a-popover>

                                    <div class="info-left">
                                        <span class="item-info-time">{{this.getWaitDurationTime(currPicture.insertTime)}}</span>
                                    </div>
                                    <div class="item-info">
                                        <!--<div class="info-left">-->
                                        <!--<span class="item-info-time">{{this.getWaitDurationTime(currPicture.insertTime)}}</span>-->
                                        <!--</div>-->
                                        <div class="info-right">
                                            <!--转发-->
                                            <div class="icon-box" style="margin-top: 10px">
                                                <!-- <span class="icon-bg-box">
                                                    <svg width="20" height="17" viewBox="0 0 20 17" fill="currentColor">
                                                        <path d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z"
                                                              transform="translate(-83.999 -132.619)"/>
                                                    </svg>
                                                </span>
                                                <span class="icon-def-box">
                                                    <svg width="20" height="17" viewBox="0 0 20 17" fill="currentColor">
                                                        <path d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z"
                                                              transform="translate(-83.999 -132.619)"/>
                                                    </svg>
                                                </span> -->
                                                <a-popover placement="bottom" v-model="popVisible">
                                                      <template #content>
                                                          <!-- shareUrl+(currPicture.id?currPicture.id:currPicture.entryId)+'?'+'pictureId='+(data.lastPicture?data.lastPicture.pictureId:data.pictureId -->
                                                        <div class="link">{{shareUrl+'/'+currPicture.entryId+'?'+'pictureId='+currPicture.pictureId}}</div>
                                                          <a-Button type="danger" class="embed" @click="onEmbed">{{$t('embeddedCode')}}</a-Button>
                                                          <a-Button class="copy" @click="onCopy">{{$t('copyLink')}}</a-Button>
                                                      </template>
                                                      <span class="icon-def-box">
                                                          <svg width="20" height="17" fill="currentColor" style="margin-top: -20px" @click="click">
                                                                 <path d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z"
                                                                       transform="translate(-83.999 -132.619)"/>
                                                          </svg>
                                                    </span>
                                                </a-popover>
                                            </div>
                                            <!--收藏-->
                                            <div class="icon-box" @click="this.favoriteHandle"
                                                 :class="currPicture.isFavorite ? 'ismy':''">
                                                <span class="icon-bg-box shoucang"
                                                      :style="{clipPath: 'polygon(0% ' + calcPer(currPicture.favoriteNum || 0) + '%, 100% ' + calcPer(currPicture.favoriteNum || 0) + '%, 100% 100%, 0% 100%)'}">
                                                </span>
                                                <span class="icon-def-box">
                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                                        <path d="M67.93,96.69a1.5,1.5,0,0,0,1.53.08L74,94.374l4.538,2.4a1.5,1.5,0,0,0,1.531-.08,1.353,1.353,0,0,0,.591-1.351l-.884-5.179,3.815-3.735a1.332,1.332,0,0,0,.335-1.4,1.431,1.431,0,0,0-1.151-.926l-5.2-.759-2.263-4.6a1.474,1.474,0,0,0-2.615,0l-2.262,4.6-5.2.759a1.43,1.43,0,0,0-1.151.926,1.333,1.333,0,0,0,.336,1.4l3.813,3.735-.885,5.179A1.354,1.354,0,0,0,67.93,96.69ZM65.214,85.471l5.188-.756.913-.133.4-.814L74,79.127l2.282,4.641.4.814.913.133,5.188.756L79,89.171l-.635.622.148.867.884,5.169-4.572-2.411L74,92.983l-.826.436L68.6,95.831l.884-5.17.148-.867L69,89.171Z"
                                                              transform="translate(-63.998 -77.942)"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <!--评论-->
                                            <!-- <div class="icon-box"
                                                 :class="isCommentMap[currPicture.pictureId] ? 'ismy':''"
                                                 @click="openComment">
                                                <span class="icon-bg-box pinglun"
                                                      :style="{clipPath: 'polygon(0% ' + calcPer(currPicture.commentNum || 0) + '%, 100% ' + calcPer(currPicture.commentNum || 0) + '%, 100% 100%, 0% 100%)' }">
                                                </span>
                                                <span class="icon-def-box">
                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                                        <path d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15zM3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z"/>
                                                    </svg>
                                                </span>
                                            </div> -->
                                            <!--点赞-->
                                            <div class="icon-box" @click="this.likeHandle"
                                                 :class="currPicture.isLike ? 'ismy':''">
                                                <span class="icon-bg-box dianzan"
                                                      :style="{clipPath: 'polygon(0% ' + calcPer(currPicture.likeNum || 0) + '%, 100% ' + calcPer(currPicture.likeNum || 0) + '%, 100% 100%, 0% 100%)'}">
                                                </span>
                                                <span class="icon-def-box">
                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                                        <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z"
                                                              transform="translate(-126 -118.666)"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <!--<div class="item-icon-tabs" @click="this.bloomHandle">-->
                                            <!--<span class="icon-bg-box guanzhu">-->
                                            <!--</span>-->
                                            <!--<span class="icon-def-box">-->
                                            <!--<svg width="18" height="22" viewBox="0 0 18 22" fill="currentColor">-->
                                            <!--<path d="M110,56.89a1.036,1.036,0,0,1-1.5-.906V36.753A1.772,1.772,0,0,1,110.291,35h14.419a1.772,1.772,0,0,1,1.791,1.753V55.984a1.036,1.036,0,0,1-1.5.907l-7.53-3.692Zm.569-19.86V54.335L117,51.158a1.057,1.057,0,0,1,.932,0l6.5,3.184V37.029Z"-->
                                            <!--transform="translate(-108.5 -35)"/>-->
                                            <!--</svg>-->
                                            <!--</span>-->
                                            <!--</div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="center-top">
                                    <div class="item-describe">
                                        <!--todo 详情描述（灰色说明没有写）-->
                                        <span class="item-info-name">{{ currPicture.note }}</span><br/>
                                        <div class="item-info-comment" id="chekItem">
                                        <div :class="['content', expande ? 'expande' : 'close']" ref="content" v-if="!currPicture.pictureUrl">
                                            <span v-html="currPicture.comment"></span>
                                            <!-- {{currPicture.comment}} -->
                                        </div>
                                        <div :class="['content', expande ? 'expande' : 'close']" ref="content" v-if="currPicture.pictureUrl">
                                            <span v-html="currPicture.comment"></span>
                                        </div>
                                                 <div
                                                class="expande-button-wrap"
                                                v-if="needShowExpande"

                                                >
                                                <div class="expande-button" @click="expandeClick" v-if="!expande" >
                                                     <img src="../assets/arrow-double-down.png">

                                                </div>
                                                <div class="expande-button" @click="expandeClick" v-else>
                                                   <img src="../assets/arrow-double-up.png">
                                                </div>
                                            </div>
                                      </div>
                                      </div>
                                </div>
                            </div>
                            <div class="item-center">
                                <div class="comment-box">
                                    <!-- 评论输入框 -->
                              <div class="comment-box">
                                  <div id="dialog-bg">
                                       <div id="dialog">

                 <div ref="textareaDiv" class="comment-text-div">
                    <textarea class="comment-text" ref="commentText" :placeholder="$t('detail.saySomething')" v-model="commentContext" v-on:keyup="textChange()">
              </textarea>
                   <div class="btn-box">
              <button :style="'background-color:'+(isActive===true?'#0870d1': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')" @click="addComment(0)">
                  {{$t('detail.suggest')}}
              </button>
              <button :style="'background-color:'+(isActive===true?'#1673CE': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')" @click="addComment(1)">
                  {{$t('detail.question')}}
              </button>
              <button :style="'background-color:'+(isActive===true?'#1673CE': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')" @click="addComment(2)">
                  {{$t('detail.comment')}}
              </button>
            </div>
                </div>
            </div>
                                  </div>

                                    <div class="tab-box">
                                        <div class="tab-item-box" id="tabItemBox">
                                            <div class="tab-item" v-for="(item,i) in tabs"
                                                 :class="item.tagId==selectTab?'active':''"
                                                 :key="item.tagId" @click="selectTabHandle(item)">{{ item.tagName }}
                                                ({{ i == 0 ? total.suggestionCnt : (i == 1 ? total.questionCnt :
                                                total.commentCnt) }})
                                            </div>
                                        </div>
                                        <div class="filter-item">
                                            <span class="active" v-show="selectSort" @click="changeHeat(1)">
                                                <svg fill="currentColor" width="11.966" height="14.196" viewBox="0 0 11.966 14.196">
                                                    <g transform="translate(0 0)">
                                                        <path data-name="路径 700" class="cls-1" d="M122.908,218.86h-10.8A.131.131,0,0,0,112,219v1.01a.131.131,0,0,0,.11.144h10.8a.131.131,0,0,0,.11-.144V219A.131.131,0,0,0,122.908,218.86Z" transform="translate(-111.627 -205.964)"/>
                                                        <path data-name="路径 706" class="cls-1" d="M10.167,8.019c-1.353-.025-3.806.108-4.98-.862C3.753,5.889,4.076,5.188,4.733,3.984c.715-.854.791-1.163.477-1.743C4.742,1.7,2.427,1.28.789,1.318c-.174,0-.764-.2-.788-.649S.513,0,.686,0C1.164-.012,5.43.174,6.6,1.609c.5.84.471,1.171-.249,2.49-.429.553-1.362,1.609.113,2.4a19.631,19.631,0,0,0,4.073.23c1.189.069.465.028.683.047a.753.753,0,0,1,.763.644c-.061.811-.778.63-.946.634C10.937,8.049,10.523,8.025,10.167,8.019Z" transform="translate(0 11.108) rotate(-68)"/>
                                                    </g>
                                                </svg>
                                                {{$t('detail.hot')}}
                                            </span>
                                            <span class="active" v-show="!selectSort" @click="changeHeat(0)">
                                                <svg fill="currentColor" width="12" height="12" viewBox="0 0 12 12">
                                                    <path data-name="路径 699" class="cls-1" d="M91.333,86.233a5.1,5.1,0,1,1-5.1,5.1,5.1,5.1,0,0,1,5.1-5.1m0-.9a6,6,0,1,0,6,6A6,6,0,0,0,91.333,85.333Z" transform="translate(-85.333 -85.333)"/>
                                                    <path data-name="路径 700" class="cls-1" d="M483.385,273.438a.542.542,0,0,1-.321-.107l-2.851-2.138a.527.527,0,0,1-.214-.428V267.2a.535.535,0,0,1,1.069,0v3.293l2.637,1.981a.535.535,0,0,1,.107.713A.527.527,0,0,1,483.385,273.438Z" transform="translate(-474.843 -264.55)"/>
                                                </svg>
                                                {{$t('detail.time')}}
                                            </span>
                                        </div>
                                        <div class="line"></div>
                                    </div>
                                    <div class="comment-div-box"  @scroll="scrollEvent">
                                        <div class="comment-list-box">
                                            <div class="comment-item" v-for="(item, commentIndex) in (infoPage[selectTab].list) ">
                                                <div class="comment-item-info">
                                                    <div class="user-ava">
                                                        <img class="img-user" :src="item.userLogo ? item.userLogo : require('@/assets/def-ava.svg')" alt="">
                                                    </div>
                                                    <div class="info-text">

                                                        <span class="comment-content-span" v-html="item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></span>

                                                        <div style="display:inline-block">
                                                            <span class="text">{{ getWaitDurationTime(item.createDate) }}</span>
                                                        <span
                                                                class="text text-btn"
                                                                @click="showDialog(commentIndex)"
                                                        >{{$t('detail.replay')}}</span>
                                                        </div>

                                                    </div>
                                                    <div class="info-dianzan">
                                                        <div class="icon-box" @click="this.commentLikeHandle(commentIndex)" :class="item.doLike == '1' ? 'ismy':''">
                                                            <span class="icon-bg-box dianzan" :style="{ clipPath: 'polygon(0% ' + calcPer(item.likes || 0) + '%, 100% ' + calcPer(item.likes || 0) + '%, 100% 100%, 0% 100%)'}">
                                                            </span>
                                                            <span class="icon-def-box">
                                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                                                    <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment-item-reply" v-if="item.replyInfos && item.replyInfos.length > 0">
                                                    <div class="reply-item-box">
                                                        <div class="reply-item" v-for="(replyItem, replyIndex) in item.replyInfos" :key="replyIndex" v-show="!pullDown || replyIndex < 3">
                                                            <div class="user-ava">
                                                                <img class="img-user" :src="replyItem.reply_userLogo ? replyItem.reply_userLogo : require('@/assets/def-ava.svg')" alt="">
                                                            </div>
                                                            <!-- @mouseover="showLike()" @mouseout="hideLike()" -->
                                                            <div class="info-text" @mouseover="showLike()">
                                                                <span class="comment-content-span" v-html="replyItem.reply_content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></span>
                                                                <!-- <span class="text">
                                                                    {{getWaitDurationTime(replyItem.reply_createDate)}}
                                                                </span> -->
                                                            </div>
                                                            <div class="info-dianzan" id="like">
                                                                <div class="icon-box" @click="this.replyLikeHandle(commentIndex, replyIndex)" :class="replyItem.reply_doLike == '1' ? 'ismy':''">
                                                                    <span class="icon-bg-box dianzan" :style="{clipPath: 'polygon(0% ' + calcPer(replyItem.reply_likes || 0) + '%, 100% ' + calcPer(replyItem.reply_likes || 0) + '%, 100% 100%, 0% 100%)'}">
                                                                    </span>
                                                                    <span class="icon-def-box">
                                                                        <svg width="16" height="15" viewBox="0 0 20 19" fill="currentColor">
                                                                            <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         <li class="fold-btn" v-if="item.replyInfos.length > 3" style="posit">
                                                            <span v-if="pullDown" @click="setPullDown(false)">{{$t('comment.more')}}>></span>
                                                            <span v-else @click="setPullDown(true)">{{$t('comment.packUp')}}</span>
                                                        </li>
                                                        <!-- <div class="fold-btn">{{ item.replyInfos.length }}{{$t('detail.totalReplyAfter')}}></div> -->
                                                    </div>
                                                </div>
                                                <div class="line"></div>
                                            </div>

                                            <div class="list-loading" v-if="commentLoading">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
         </div>
        </div>
        <my-dialog
                :content="replyInfo.replyContext"
                :showDialog="isShowDialog"
                @submit="addReply"
                @closeDialog="isShowDialog=false"
        ></my-dialog>
    </a-modal>
    <Login
            :visible="visible"
            :closeModal="closeModal"
    />
</template>

<script>
    import Api from "../Api";
    import moment from "moment";
    import storage from "storejs";
    import MyDialog from "@/components/mdialog.vue";
    import CommentDialog from "@/components/Commentdialog.vue";
    import {calcPercentage} from '@/utils/common';
    import Login from "../views/Login";
    import {shareURL} from '@/utils/request';
    import elementResizeDetectorMaker from "element-resize-detector";
    import {autoTextarea,autoTextareaDivChange} from '@/utils/common';

    export default {
        emits: ['onEmbed', 'onCopy'],
        components: {
            MyDialog,
            CommentDialog,
            Login
        },
        computed: {
            tabs() {
                return [{tagName: this.$t('detail.suggest'), tagId: 0}, {
                    tagName: this.$t('detail.question'),
                    tagId: 1
                }, {tagName: this.$t('detail.comment'), tagId: 2}]
            }
        },

        emits: ['goInDetail','goToInDetail'],
        data() {
            return {
                expande: false,
                needShowExpande: false,
                pullDown:true,
                content: '',
                isA:true,
                selectSort: false,
                popVisible: false,
                shareUrl: '',
                closable: false,
                visibleInfo: false,
                visibleSecond: false,
                detailInfoVisible: false,
                isShowDialog: false,
                showSide: false,
                // tabs: [{tagName: '建议', tagId: 0}, {tagName: '提问', tagId: 1}, {tagName: '评论', tagId: 2}],
                selectTab: 0,
                activeTab: 1,
                showFilter: false,
                filterStyle: {
                    right: 0,
                    top: 0,
                },
                loading: false,
                commentLoading: false,
                commentContext: '',
                replyInfo: {
                    commentIndex: undefined,
                    replyContent: undefined,
                    commentId: undefined,
                    replyUserId: undefined,
                },
                entryId: this.$route.params.id,
                entryName: undefined,
                datas: [],
                page: 1,
                totalPage: 1,
                pageSize: 20,
                currPictureIndex:0,
                currPicture: {},

                isCommentMap: {},

                isHeat: 0,
                commentCondition: {
                    type: 0,
                    pictureId: undefined,
                },
                initPage: {
                    page: 1,
                    totalPage: 1,
                    pageSize: 20,
                    list: []
                },
                infoPage: [
                    {
                        page: 1,
                        totalPage: 1,
                        pageSize: 20,
                        list: []
                    },
                    {
                        page: 1,
                        totalPage: 1,
                        pageSize: 20,
                        list: []
                    },
                    {
                        page: 1,
                        totalPage: 1,
                        pageSize: 20,
                        list: []
                    },
                ],

                list: [],

                rightFilterTabShow: false,

                filterTags: [],
                filterDate: '',
                filterSelect: '',
                filter: {
                    filterDate: '',
                    filterSelect: '',
                    page: 1,
                    totalPage: 1,
                    pageSize: 20,
                    list: []
                },
                impression: {
                    page: 1,
                    totalPage: 1,
                    pageSize: 20,
                    list: []
                },
                total: {
                    suggestionCnt: 0,
                    questionCnt: 0,
                    commentCnt: 0
                },
                isReply: false,
                visible: false,
                rightAllLoading: false,
                rightTabLoading: false,

                showEmoji: true,
                pictureLoading: false,
                isActive: false,
                naturalWidth: 0,
                naturalHeight: 0

            }

        },
        created() {
            if (!this.visibleSecond && !this.isShowDialog) document.addEventListener('keyup', this.escEvent)
        },

        mounted() {
            this.shareUrl = shareURL;
            this.getTagByRecommend();
            let imgInit = setInterval(() =>{
                let img = document.querySelector('.item-list-img-info')
                if (!Number.isNaN(parseInt($(img).css("width")))) {
                    clearTimeout(imgInit)
                }
                this.naturalWidth = parseInt($(img).css("width"))
                this.naturalHeight = parseInt($(img).css("height"))
            },1000)
        },
        methods: {
            listenChange(){
                const _this = this;
                const erdm = elementResizeDetectorMaker()
                let lineHeight = 22
                erdm.listenTo(this.$refs.content, element => {
                    if(element.offsetHeight < lineHeight*3 ){
                        this.needShowExpande = false;
                    }else{
                         this.needShowExpande = true;
                       }
                 })

            },
        expandeClick() {
            this.expande = !this.expande
        },
          textChange() {
          const _this = this;
          const erd = elementResizeDetectorMaker();
          erd.listenTo(this.$refs.textareaDiv, element => {
              _this.$nextTick(() => {
                  autoTextareaDivChange(this.$refs.commentText)
              });
          });
          autoTextarea(this.$refs.commentText)
                if (!this.commentContext) {
                this.isActive = false
                } else {
                this.isActive = true
                }
            },
            showLike(){
             var div = document.getElementsByClassName("reply-item");
             for(var i =0;i<div.length;i++){
                div[i].onmouseover = function(e){
                        var xuan = this.getElementsByClassName("info-dianzan")[0];
                        xuan.style.display = "block";
                };
                div[i].onmouseout = function(e){
                        var xuan = this.getElementsByClassName("info-dianzan")[0];
                        xuan.style.display = "none";
                };
             }
            },
            setPullDown (data) {
                this.pullDown = data;
            },

            // 评论显示表情
            emotion (res) {
                let word = res.replace(/\#|\;/gi,'')
                const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
                let index = list.indexOf(word)
                return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`
            },
            goGuge(key) {
              window.open('https://www.google.com/search?q='+key,"_blank")
            },
            goWeiji(key) {
              window.open('https://'+this.$i18n.locale+'.wikipedia.org/wiki/'+key,"_blank")
            },
            // 复制成功时的回调函数
            onCopy () {
               this.popVisible = false
               this.$emit('onCopy');
            },
            click () {
              this.popVisible = true
            },
            onEmbed () {
               this.popVisible = false
               this.$emit('onEmbed');
            },
            // 左右箭头事件
            escEvent() {
                if (!this.visibleSecond && !this.isShowDialog) {
                    if (window?.event?.keyCode == 37) this.imgLast()
                    if (window?.event?.keyCode == 39) this.imgNext()
                }
            },
            openComment() {
                if (this.checkLogin()) {
                    return;
                }
                this.visibleSecond = true
                this.$nextTick(()=>{
                    $('#textarea')[0].select()
                })
            },
            // 改变class
             chang(){
                this.isA = !this.isA;
            },
            // 图片变大
           imgbig() {
                    const img = document.querySelector('.item-list-img-info');
                    $('.item-list-img-info').css("width", parseInt($(img).css("width")) * 1.2 + 'px');
                    $('.item-list-img-info').css("height", parseInt($(img).css("height")) * 1.2 + 'px');
           },
            // 图片变小
            imgsmall() {
                const img = document.querySelector('.item-list-img-info');
                if (parseInt($(img).css("height")) > 6 && parseInt($(img).css("width")) > 6) {
                     $('.item-list-img-info').css("height", parseInt($(img).css("height")) / 1.2 + 'px');
                    // if (img.width / img.height > naturalWidth / naturalHeight) $('.item-list-img-info').css("width", parseInt($(img).css("width")) / 1.2 + 'px');
                    $('.item-list-img-info').css("width", parseInt($(img).css("width")) / 1.2 + 'px');
                 }
            },
            imgLast() {
                let newPicture =null
                if (this.currPicture !== this.datas[0]) {
                    // 图片变回初始大小
                    $('.item-list-img-info').css("width", this.naturalWidth + 'px');
                    $('.item-list-img-info').css("height", this.naturalHeight + 'px');
                    // let index = this.datas.findIndex(obj => obj.pictureId === this.currPicture.pictureId) - 1
                    let index = --this.currPictureIndex
                    if (this.currPicture.pictureUrl !== this.datas[index].pictureUrl) {
                        $('.item-list-img-info').fadeOut(200);
                    }
                    newPicture = this.datas[index];
                    setTimeout(() => {
                        if (this.currPicture !== this.datas[index]) {
                            this.currPicture = this.datas[index];
                        }
                    }, 100)
                    if (this.currPicture.isComment) {
                        this.isCommentMap[this.currPicture.pictureId] = true;
                    }
                    // this.commentCondition.type = 0;
                    this.commentCondition.pictureId = newPicture.pictureId;
                    this.infoPage[0] = {...this.initPage};
                    this.infoPage[1] = {...this.initPage};
                    this.infoPage[2] = {...this.initPage};
                    this.total = {
                        suggestionCnt: newPicture.suggestionCnt,
                        questionCnt: newPicture.questionCnt,
                        commentCnt: newPicture.commentCnt,
                    }
                    for (let i = 0; i <3 ; i++) {
                        this.getCommentPage(i);
                    }

                }
            },
            imgNext() {
                let newPicture =null
                if (this.currPicture !== this.datas[this.datas.length - 1]) {
                    $('.item-list-img-info').css("width", this.naturalWidth + 'px');
                    $('.item-list-img-info').css("height", this.naturalHeight + 'px');
                    // let index = this.datas.findIndex(obj => obj.pictureId === this.currPicture.pictureId) + 1
                    let index = ++this.currPictureIndex
                    if (this.currPicture.pictureUrl !== this.datas[index].pictureUrl) {
                        $('.item-list-img-info').fadeOut(200);
                    }
                    newPicture = this.datas[index];
                    setTimeout(() => {
                        if (this.currPicture !== this.datas[index]) {
                            this.currPicture = this.datas[index];
                        }
                    }, 100)
                    if (newPicture.isComment) {
                        this.isCommentMap[newPicture.pictureId] = true;
                    }
                    // this.commentCondition.type = 0;
                    this.commentCondition.pictureId = newPicture.pictureId;
                    this.infoPage[0] = {...this.initPage};
                    this.infoPage[1] = {...this.initPage};
                    this.infoPage[2] = {...this.initPage};
                    this.total = {
                        suggestionCnt: newPicture.suggestionCnt,
                        questionCnt: newPicture.questionCnt,
                        commentCnt: newPicture.commentCnt,
                    }

                    for (let i = 0; i <3 ; i++) {
                        this.getCommentPage(i);
                    }
                }
            },
            handleCancel() {
                this.visibleInfo = false
                $('.item-list-img-info').css("width", this.naturalWidth + 'px');
                $('.item-list-img-info').css("height", this.naturalHeight + 'px');
                this.$nextTick(() => {
                    this.$emit('refreshDataList')
                })
            },
            init(currPicture, datas, index) {
                this.visibleInfo = true
                this.datas = datas
                this.currPicture = currPicture
                this.currPictureIndex = index
                if (currPicture.isComment) {
                    this.isCommentMap[currPicture.pictureId] = true;
                }
                // this.commentCondition.type = 0;
                this.commentCondition.pictureId = currPicture.pictureId;
                this.infoPage[0] = {...this.initPage};
                this.infoPage[1] = {...this.initPage};
                this.infoPage[2] = {...this.initPage};
                this.total = {
                    suggestionCnt: currPicture.suggestionCnt,
                    questionCnt: currPicture.questionCnt,
                    commentCnt: currPicture.commentCnt,
                }
                for (let i = 0; i <3 ; i++) {
                    this.getCommentPage(i);
                }
                // this.imageInitialization()
            },
            clickEmoji() {
                this.showEmoji = !this.showEmoji;
            },
            calcPer(val) {
                if (!val) {
                    return 100;
                }
                if (typeof val == "string") {
                    val = parseInt(val);
                }
                return 100 - calcPercentage(val);
            },
            showDialog(commentIndex) {
                if (this.checkLogin()) {
                    return;
                }
                this.isShowDialog = true;

                let comment = this.infoPage[this.selectTab].list[commentIndex];
                this.replyInfo = {
                    commentIndex: commentIndex,
                    replyContent: undefined,
                    commentId: comment.commentId,
                    replyUserId: comment.userId,
                };
                this.$nextTick(()=>{
                    $('#textarea')[0].select()
                })
            },

            addReply(content = undefined) {
                if (!content) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (userInfo) {
                    let params = {
                        ...this.replyInfo,
                        userId: userInfo.userid,
                        treeId: this.currPicture.entryId,
                        imageId: this.currPicture.pictureId,
                        content: content,
                    }

                    console.log("add reply params ==>", params);
                    let _that = this;
                    Api.addReply(params).then((res) => {
                        this.replyInfo = {
                            commentIndex: undefined,
                            replyContent: undefined,
                            commentId: undefined,
                            replyUserId: undefined,
                        };
                        if (res.error_code == 0) {
                            this.$message.success(this.$t('detail.replaySuccess'));
                            console.log("reply res ==>", res);
                            this.isShowDialog = false
                            this.isActive = false
                            const data = res.result.data;
                            const info = {
                                replyId: data.replyId,
                                reply_commentId: data.commentId,
                                reply_content: data.content,
                                reply_createDate: data.createDate,
                                reply_doLike: data.doLike,
                                reply_likes: data.likes,
                                reply_userId: data.userId,
                                reply_userLogo: data.userLogo,
                                reply_userName: ''
                            };
                            _that.infoPage[_that.selectTab].list[params.commentIndex].replyInfos = (_that.infoPage[_that.selectTab].list[params.commentIndex].replyInfos || []);
                            _that.infoPage[_that.selectTab].list[params.commentIndex].replyInfos.unshift(info);
                            // this.infoPage[type] = {...this.initPage};
                            // this.getCommentPage(type);
                        }
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                    )
                }
            },
            closeModal() {
                this.visible = false;
            },
            onload(){
                $('.item-list-img-info').hide();
                $('.item-list-img-info').fadeIn(200);
                  this.listenChange();

            },
            changeHeat(isHeat, type = undefined) {
                this.selectSort = !this.selectSort
                this.isHeat = isHeat;
                this.infoPage[0] = {...this.initPage};
                this.infoPage[1] = {...this.initPage};
                this.infoPage[2] = {...this.initPage};
                this.getCommentPage(type);
            },
            //Header
            targetSide() {
                this.showSide = !this.showSide
            },
            getTagByRecommend() {
                Api.getTagByRecommend("filter_recommend").then((res) => {
                    let data = res.result.data;
                    this.filterTags = data;
                })
            },
        getCommentPage(type = undefined) {
        type = type == undefined ? this.commentCondition.type : type;
        let page = this.infoPage[type];
        if (page.totalPage < page.page) {
          this.commentLoading = false
          return;
        }
        Api.getCommentPage(this.commentCondition.pictureId, type,
            this.isHeat,
            page.page, page.pageSize).then((res) => {
          if (res.error_code == 0) {
            let data = res.result.data;
            let newList = data.list.filter(value => this.infoPage[type].list.map(v => v.commentId ).indexOf(value.commentId) === -1)
            this.infoPage[type].list = this.infoPage[type].list.concat(newList);
            this.infoPage[type].totalPage = data.totalPageCount;
            this.infoPage[type].page++;
            switch (type) {
              case 0:
                this.total.suggestionCnt = data.totalSize;
                break;
              case 1:
                this.total.questionCnt = data.totalSize;
                break;
              case 2:
                this.total.commentCnt = data.totalSize;
                break;
            }
          }

        }).catch(err => {
          console.log("Get Comment Page ==> ", err)
        }).finally(() => {
          this.commentLoading = false
        });
      },
            //评论筛选
            selectTabHandle(val) {
                this.selectTab = val.tagId;
                this.commentCondition.type = this.selectTab;
                if (this.infoPage[this.selectTab].page <= this.infoPage[this.selectTab].totalPage) {
                    this.getCommentPage();
                }
            },
            // 右侧tab
            changeTab(val) {
                this.activeTab = val
            },
            scrollEvent(event) {
                if ($('#tabItemBox').offset().top <= 0) {
                    let tNum = -(52 + $('#tabItemBox').offset().top);
                    if (tNum >= 0) {
                        tNum = 0
                    }
                }
                if (!this.commentLoading) {
                    if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
                        this.commentLoading = true
                        this.getCommentPage()
                    }
                }
            },

            //右侧的
            scrollEvent1(event) {
                if (!this.rightAllLoading) {
                    if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
                        this.rightAllLoading = true
                        this.getPicturePage()
                    }
                }

            },

            //过滤器
            showFilterHandler() {
                this.filterStyle = {
                    right: $(window).width() - $('.filter').offset().left - $('.filter').width() - 20 + 'px',
                    top: $('.filter').offset().top + 22 + 'px',
                };
                this.showFilter = true;
            },
            hideFilterHandle() {
                this.showFilter = false;
            },
            closeTab(e) {
                e.stopPropagation();
                e.preventDefault();
                this.rightFilterTabShow = false;
                if (this.activeTab == 3) {
                    this.changeTab(1);
                }
            },

            filterSelectHandle(val) {
                if (this.filterSelect == val.tagId) {
                    this.filterSelect = '';
                } else {
                    this.filterSelect = val.tagId;
                }
            },
            checkLogin() {
                this.visible = !localStorage.getItem('token');
                return this.visible;
            },
            addComment(type, content = undefined, dialogFlog) {
            if (this.checkLogin()) {
            return;
            }
            if (dialogFlog) {
            if (!content) {
            return;
             }
           } else {
               if (!this.commentContext) {
                  return;
              }
            }
              let userInfo = storage.get('userInfo') || '';
            if (userInfo) {
            this.commentContext = this.commentContext.replace(/\n/g, '<br>');
            let params = {
            userId: userInfo.userid,
            treeId: this.currPicture.entryId,
            imageId: this.currPicture.pictureId,
            content: dialogFlog === 'dialogFlog' ? content : this.commentContext,
            type: type
            }
            console.log("add comment params ==>", params);
            Api.addComment(params).then((res) => {
            this.commentContext = '';
            if (res.error_code == 0) {
              this.$message.success(this.$t('detail.commentSuccess'));
               this.infoPage[type] = {...this.initPage};
            this.getCommentPage(type);
            this.isCommentMap[params.imageId] = true;
            }
            }).catch(err => {
            if (err.message.indexOf('code 400') > -1) {
            this.$message.error(this.$t("tokenErrMessage"));
            this.visible = true
                             }
                           }
                          )
                         }
                      },
            favoriteHandle() {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (userInfo) {
                    let params = {
                        userId: userInfo.userid,
                        imageId: this.currPicture.pictureId
                    }
                    if (this.currPicture.isFavorite) {
                        Api.postCancelFavorite(params).then((res) => {
                            this.currPicture.isFavorite = false
                            this.$message.success(this.$t("detail.cancelCollectionSuccess"));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    } else {
                        Api.postFavorite(params).then((res) => {
                            this.currPicture.isFavorite = true
                            this.$message.success(this.$t("detail.collectionSuccess"));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    }
                }

            },

            likeHandle() {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (userInfo) {
                    let params = {
                        pictureId: this.currPicture.pictureId
                    }
                    if (this.currPicture.isLike) {
                        Api.postpictureLike(params).then((res) => {
                            this.currPicture.isLike = false
                            this.$message.success(this.$t('detail.cancelLikeSuccess'));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    } else {
                        Api.postpictureLike(params).then((res) => {
                            this.currPicture.isLike = true
                            this.$message.success(this.$t('detail.likeSuccess'));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    }
                }
            },

            commentLikeHandle(commentIndex) {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (userInfo) {
                    let comment = this.infoPage[this.selectTab].list[commentIndex];
                    let params = {
                        imageId: this.currPicture.pictureId,
                        commentId: comment.commentId,
                        userId: userInfo.userid,
                        treeId: comment.entryId
                    }
                    if (comment.doLike && comment.doLike != '0') {
                        Api.commentLike(params).then((res) => {
                            this.infoPage[this.selectTab].list[commentIndex].doLike = '0'
                            this.$message.success(this.$t('detail.cancelLikeSuccess'));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    } else {
                        Api.commentLike(params).then((res) => {
                            this.infoPage[this.selectTab].list[commentIndex].doLike = '1'
                            this.$message.success(this.$t('detail.likeSuccess'));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    }
                }
            },

            replyLikeHandle(commentIndex, replyIndex) {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (userInfo) {
                    let comment = this.infoPage[this.selectTab].list[commentIndex];
                    let reply = comment.replyInfos[replyIndex];
                    let params = {
                        imageId: this.currPicture.pictureId,
                        commentId: comment.commentId,
                        replyId: reply.replyId,
                        userId: userInfo.userid,
                        treeId: comment.entryId
                    }
                    if (reply.reply_doLike && reply.reply_doLike != '0') {
                        Api.replyLike(params).then((res) => {
                            this.infoPage[this.selectTab].list[commentIndex].replyInfos[replyIndex].reply_doLike = '0'
                            this.$message.success(this.$t('detail.cancelLikeSuccess'));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    } else {
                        Api.replyLike(params).then((res) => {
                            this.infoPage[this.selectTab].list[commentIndex].replyInfos[replyIndex].reply_doLike = '1'
                            this.$message.success(this.$t('detail.likeSuccess'));
                        }).catch(err => {
                                if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
                    }
                }
            },
            getWaitDurationTime(start, end = moment()) {
              let timeZone = new Date().getTimezoneOffset()/60
              if (typeof timeZone !== "number") {
                return new Date();
              }
                if (start, timeZone) {
                    let startTime = moment(start);
                    let newStart = new Date(startTime - 3600000 * timeZone);
                    let newTime = moment(newStart);
                    let endTime = moment(end);
                    if (newTime.isSame(end)) {
                        return ''
                    }

                    let waitTime = moment.duration(endTime - newTime, 'ms');
                    let years = waitTime.get('years');
                    let months = waitTime.get('months');
                    let days = waitTime.get('days');
                    let hours = waitTime.get('hours');
                    let minutes = waitTime.get('minutes');

                    if (!years && !months && !days && !hours) {
                        if (minutes >= 1) {
                            return minutes + this.$t('time.minutesAgo')
                        } else {
                            return this.$t('time.justNow')
                        }
                    } else if (!years && !months && !days && hours) {
                        if (hours > 1) {
                            return hours + this.$t('time.hoursAgo')
                        } else {
                            return hours + this.$t('time.hourAgo')
                        }
                    } else if (!years && !months && days) {
                        if (days > 1) {
                            return days + this.$t('time.daysAgo')
                        } else {
                            return days + this.$t('time.dayAgo')
                        }
                    } else if (!years && months) {
                        if (months > 1) {
                            return months + this.$t('time.monthsAgo')
                        } else {
                            return months + this.$t('time.monthAgo')
                        }
                    } else if (years) {
                        if (years > 1) {
                            return years + this.$t('time.yearsAgo')
                        } else {
                            return years + this.$t('time.yearAgo')
                        }
                    } else {
                        return moment(newTime).format('YYYY.M.D HH:mm:ss')
                    }

                }
                return ''
            },
        // goInDetail(entryId, entryTitle, pictureId) {
        //     Api.saveHistory(entryId, entryTitle, pictureId);
        //     window.open(`/${entryId}`)
        // },
         goToInDetail(entryId, entryTitle, pictureId) {
            Api.saveHistory(entryId, entryTitle, pictureId);
            window.open(`/toDetail/${entryId}/${pictureId}`)
        },
        }
    }
</script>
<style lang="scss">
    .detail-modal-wrap {
        .ant-modal {
            box-sizing: border-box;
            /*margin: 0;*/
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum';
            position: relative;
            /*top: 100px;*/
            width: auto;
            margin: 0 auto;
            pointer-events: none;
            .ant-modal-content {
                .ant-modal-body {
                    padding: 5px 5px 0 5px;
                    font-size: 14px;
                    line-height: 1.5715;
                    word-wrap: break-word;
                    font-family: "Microsoft YaHei";
                    .detail-box {
                        width: 100%;
                        /*height: 100%;*/
                        height: 97.95vh;
                        overflow-y: auto;

                        .container {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin: 0 auto;

                            .container-c {
                                width: 100%;
                                height: 100%;
                                display: flex;

                                .container-c-left {
                                    min-width: 540px;
                                    height: 100%;
                                    width: 72%;
                                    .item-list {
                                        height: 100%;
                                        background: #000000;
                                        position: relative;
                                        .item-icon-tabs {
                                            position: absolute;
                                            right: 20px;
                                            top: 20px;
                                            cursor: pointer;
                                            color: #999;

                                            &.ismy {
                                                color: #f69c4a;
                                            }

                                            .icon-bg-box {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                overflow: hidden;
                                                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                                                &.guanzhu {
                                                    background-image: url("../assets/follow.svg");
                                                    background-repeat: no-repeat;
                                                    background-position: -17px -17px;
                                                    background-size: 52px;
                                                }
                                            }

                                            .icon-def-box {
                                                position: relative;
                                                top: 0;
                                                left: 0;
                                                width: 20px;
                                                height: 20px;
                                                display: inline-block;
                                            }
                                        }

                                        .item-list-imgbox {
                                            width: 100%;
                                            /*height: 97.9vh;*/
                                            height: 100%;
                                            /*min-width: 100%;*/
                                            /*padding-bottom: 84.4%;*/
                                            position: relative;
                                            .cross {
                                                margin-left: 20px;
                                                border-radius: 5px 0 0 5px;
                                                width: 40px;
                                                height: 35px;
                                                font-weight: bold;
                                                color: #ffffff;
                                                background-color: #212428;
                                                position: absolute;
                                                z-index: 98;
                                                cursor: pointer;
                                                opacity: 0.8;
                                                .cross-img {
                                                    /*margin-top: 2.5px;*/
                                                    margin-left: 2.5px;
                                                    position: absolute;
                                                    z-index: 98;
                                                }
                                            }
                                            .plus {
                                                cursor: pointer;
                                                margin-left: 100px;
                                                border-radius: 0  5px 5px 0;
                                                width: 40px;
                                                height: 35px;
                                                font-weight: bold;
                                                color: #ffffff;
                                                background-color: #212428;
                                                position: absolute;
                                                z-index: 98;
                                                -webkit-user-select: none;
                                                -moz-user-select: none;
                                                -ms-user-select: none;
                                                user-select: none;
                                                opacity: 0.8;
                                                .plus-img {
                                                    margin-top: 10px;
                                                    margin-left: 12.5px;
                                                    position: absolute;
                                                    z-index: 98;
                                                }
                                            }
                                            .minus {
                                                cursor: pointer;
                                                margin-left: 60px;
                                                width: 40px;
                                                height: 35px;
                                                font-weight: bold;
                                                color: #ffffff;
                                                background-color: #212428;
                                                position: absolute;
                                                z-index: 98;
                                                -webkit-user-select: none;
                                                -moz-user-select: none;
                                                -ms-user-select: none;
                                                user-select: none;
                                                opacity: 0.8;
                                                .minus-img {
                                                    margin-top: 9.5px;
                                                    margin-left: 12px;
                                                    position: absolute;
                                                    z-index: 98;
                                                }
                                            }
                                            .item-list-img-last {
                                                position: absolute;
                                                z-index: 98;
                                                border-radius: 50px;
                                                width: 50px;
                                                height: 50px;
                                                top: 45%;
                                                left: 30px;
                                                cursor: pointer;
                                            }
                                            .item-list-img-next {
                                                position: absolute;
                                                z-index: 98;
                                                border-radius: 50px;
                                                width: 50px;
                                                height: 50px;
                                                top: 45%;
                                                right: 30px;
                                                cursor: pointer;
                                            }
                                            .item-list-imgbox-center {
                                                width: 100%;
                                                height: 100%;
                                                min-width: 100%;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                right: 0;
                                                bottom: 0;
                                                padding-bottom: 5px;
                                                overflow: hidden;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                -webkit-user-select: none;
                                                -moz-user-select: none;
                                                -ms-user-select: none;
                                                user-select: none;
                                            }

                                            .item-list-img-info {
                                                object-fit: contain;
                                                width: 100%;
                                                height: 100%;
                                            }
                                            .waite-img{
                                                object-fit: contain;
                                                width: 5%;
                                            }
                                            .list-loading {
                                                    display: flex;
                                                    align-items: center;
                                                    margin-top: 20px;
                                                    height: 40px;
                                                    justify-content: center;

                                                    > span {
                                                    display: inline-block;
                                                    width: 16px;
                                                    height: 16px;
                                                    margin-right: 6px;
                                                    border-radius: 50%;
                                                    background: #ebebeb;
                                                    -webkit-animation: load 1.04s ease infinite;

                                                    &:last-child {
                                                        margin-right: 0px;
                                                    }
                                                    }

                                                    @-webkit-keyframes load {
                                                    0% {
                                                        opacity: 1;
                                                    }
                                                    100% {
                                                        opacity: 0;
                                                    }
                                                    }

                                                    span:nth-child(1) {
                                                    -webkit-animation-delay: 0.13s;
                                                    }

                                                    span:nth-child(2) {
                                                    -webkit-animation-delay: 0.26s;
                                                    }

                                                    span:nth-child(3) {
                                                    -webkit-animation-delay: 0.39s;
                                                    }
                                                }
                                        }

                                    }
                                }

                                .container-c-right {
                                    margin-left: 10px;
                                    width: 760px;
                                    height: 97.9vh;
                                    flex: 1;
                                    .container-right-box {
                                        width: 100%;
                                        height: 97.9vh;
                                        display: flex;
                                        flex-direction: column;
                                        .container-right-box-top {
                                            border-radius: 10px;
                                            padding: 17.7px 0 0 20.8px;
                                            width: 100%;
                                            min-height: 50px;
                                            height:auto;
                                            background-color: #ffffff;
                                            flex:none;
                                            .item-title {
                                                width: 100%;
                                                display: flex;
                                                flex-wrap: wrap;
                                                .item-list-title {
                                                    cursor: pointer;
                                                    float: left;
                                                    font-size: 25px;
                                                    font-weight: bold;
                                                    color: #0870d1;
                                                    margin-right:16px;
                                                }
                                                .info-left {
                                                    min-width: 100px;
                                                    flex: 1;
                                                    float: left;
                                                    display: flex;
                                                    align-items: flex-end;
                                                    .item-info-time {
                                                        font-size: 13px;
                                                        font-weight: 300;
                                                        color: #999999;
                                                    }
                                                }
                                                .item-info {
                                                    /*width: 500px;*/
                                                    float: left;
                                                    line-height: 24px;
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    align-items: flex-end;
                                                    /*.info-left {*/
                                                    /*min-width: 150px;*/
                                                    /*flex: 1;*/
                                                    /*width: 200px;*/
                                                    /*float: left;*/
                                                    /*.item-info-time {*/
                                                    /*font-size: 16px;*/
                                                    /*font-weight: 400;*/
                                                    /*color: #999999;*/
                                                    /*}*/
                                                    /*}*/

                                                    .info-right {
                                                        position: relative;
                                                        right: 30px;
                                                        color: #999;
                                                        float: right;
                                                        .icon-box {
                                                            width: 20px;
                                                            height: 20px;
                                                            display: inline-block;
                                                            position: relative;
                                                            margin-left: 30px;
                                                            vertical-align: middle;
                                                            line-height: 1;
                                                            cursor: pointer;

                                                            .icon-bg-box {
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                width: 100%;
                                                                height: 100%;
                                                                overflow: hidden;
                                                                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                                                                &.shoucang {
                                                                    background-image: url("../assets/collection.svg");
                                                                    background-repeat: no-repeat;
                                                                    background-position: -10px -11px;
                                                                }

                                                                &.pinglun {
                                                                    background-image: url("../assets/comment.svg");
                                                                    background-repeat: no-repeat;
                                                                    background-position: -10px -9px;
                                                                }

                                                                &.dianzan {
                                                                    background-image: url("../assets/like.svg");
                                                                    background-repeat: no-repeat;
                                                                    background-position: -10px -10px;
                                                                }
                                                            }

                                                            .icon-def-box {
                                                                position: relative;
                                                                top: 0;
                                                                left: 0;
                                                                width: 20px;
                                                                height: 20px;
                                                                display: inline-block;
                                                            }

                                                            &.ismy {
                                                                color: #f69c4a;
                                                            }
                                                        }

                                                        .item-icon-tabs {
                                                            display: none;

                                                            .icon-bg-box {
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                width: 100%;
                                                                height: 100%;
                                                                overflow: hidden;
                                                                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                                                                &.guanzhu {
                                                                    background-image: url("../assets/follow.svg");
                                                                    background-repeat: no-repeat;
                                                                    background-position: -17px -17px;
                                                                    background-size: 52px;
                                                                }
                                                            }

                                                            .icon-def-box {
                                                                position: relative;
                                                                top: 0;
                                                                left: 0;
                                                                width: 20px;
                                                                height: 20px;
                                                                display: inline-block;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .center-top {
                                                background: #fff;
                                                width: 98%;

                                                .item-describe {
                                                    width: 100%;
                                                    height: 100%;
                                                    .item-info-name {
                                                        /*width: 100%;*/
                                                        text-align: center;
                                                        font-size: 22px;
                                                        font-weight: bold;
                                                        line-height: 54px;
                                                    }
                                                    .item-info-comment{
                                                        display: flex;
                                                        align-items:flex-end;
                                                        margin-bottom: 10px;
                                                    }
                                                    // .tip{
                                                    //     img{
                                                    //        width: 25px;
                                                    //        height: 20px;
                                                    //     }
                                                    //   }
                                                      .expande-button-wrap{
                                                          margin-left: 10%;
                                                          img{
                                                            width: 25px;
                                                            height: 20px;
                                                          }
                                                      }
                                                     .expande {
                                                        height: auto;
                                                        line-height: 22px;
                                                        // width: 90%;
                                                        width: 400px;
                                                        font-size: 14px;
                                                        font-family:"Microsoft YaHei"

                                                        }
                                                        .close {
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        -webkit-line-clamp: 3;
                                                        -webkit-box-orient: vertical;
                                                        display: -webkit-box;
                                                        line-height: 22px;
                                                        width: 400px;
                                                        font-size: 14px;
                                                        font-family:"Microsoft YaHei"
                                                        }
                                                    // .item-a{
                                                    //     font-size: 14px;
                                                    //     font-weight: 400;
                                                    //     line-height: 19px;
                                                    //     color: #999999;
                                                    //     opacity: 1;
                                                    //     margin-bottom: 10px;
                                                    //     text-overflow: ellipsis;
                                                    //     overflow: hidden;
                                                    //     -webkit-line-clamp: 3;
                                                    //     display: -webkit-box;
                                                    //     -webkit-box-orient: vertical;
                                                    //     cursor: pointer;
                                                    // }
                                                    //   .item-b{
                                                    //     font-size: 14px;
                                                    //     font-weight: 400;
                                                    //     line-height: 19px;
                                                    //     color: #999999;
                                                    //     opacity: 1;
                                                    //     margin-bottom: 10px;
                                                    //     cursor: pointer;
                                                    // }
                                                }
                                            }
                                        }

                                        .item-center {
                                            margin-top: 10px;
                                            width: 100%;
                                            height: calc(20vh);
                                            flex: 1;
                                            .analyse-box {
                                                width: 100%;
                                                height: 119px;
                                                padding: 28px 51px;
                                                background: #fff;
                                                margin-top: 20px;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                }
                                            }

                                            .comment-box {
                                                padding: 10px;
                                                background: #fff;
                                                height: 100%;
                                                overflow: auto;
                                                border-radius: 10px;
                                                display: flex;
                                                flex-direction: column;

                                                .tab-box {
                                                    min-width: 433px;
                                                    width: 100%;
                                                    height: 35px;
                                                    margin-top: 10px;
                                                    display: flex;
                                                    position: relative;
                                                    flex-direction: column;
                                                    flex-wrap: wrap;
                                                    align-content: space-between;
                                                    justify-content: flex-end;
                                                    .tab-item-box {
                                                        min-width: 240px;
                                                        flex: 1;
                                                        height: 35px;

                                                        .tab-item {
                                                            color: #cccccc;
                                                            font-size: 16px;
                                                            font-weight: 400;
                                                            line-height: 24px;
                                                            margin-right: 20px;
                                                            display: inline-block;
                                                            cursor: pointer;

                                                            &.active {
                                                                color: #333333;
                                                                font-weight: bold;
                                                                border-bottom: 2px solid #0870d1;
                                                                padding-bottom: 9px;
                                                            }
                                                        }
                                                    }

                                                    .filter-item {
                                                        > span {
                                                            margin-left: 22px;
                                                            font-size: 12px;
                                                            font-weight: 400;
                                                            line-height: 16px;
                                                            color: #666666;
                                                            cursor: pointer;

                                                            &.active {
                                                                color: #0870d1;
                                                            }

                                                            > svg {
                                                                margin-right: 2px;
                                                                position: relative;
                                                                top: 1px;
                                                            }
                                                        }
                                                    }

                                                    .line {
                                                        position: absolute;
                                                        height: 1px;
                                                        left: -20px;
                                                        right: -20px;
                                                        bottom: 0;
                                                        background: #eeeeee;
                                                    }
                                                }
                                                .comment-div-box{
                                                    margin-top: 20px;
                                                    height: 90%;
                                                    width: 100%;
                                                    overflow: auto;
                                                    max-height: 90%;
                                                    .comment-item {
                                                        display: flex;
                                                        position: relative;
                                                        padding-bottom: 20px;
                                                        flex-wrap: wrap;
                                                        flex-direction: column;
                                                        .comment-item-info {
                                                            display: flex;
                                                            width: 425px;
                                                            width: 100%;
                                                            min-width: 350px;
                                                            position: relative;

                                                            .user-ava {
                                                                width: 30px;
                                                                height: 30px;
                                                                margin-right: 16px;
                                                                .img-user {
                                                                    width: 30px;
                                                                    height: 30px;
                                                                    border-radius: 50%;
                                                                }
                                                            }

                                                            .info-text {
                                                                padding-top: 4px;
                                                                flex: 1;
                                                                overflow: hidden;
                                                                word-break: break-word;
                                                                font-size: 14px;
                                                                font-weight: 400;
                                                                line-height: 24px;
                                                                color: #333333;
                                                                margin-right:8%;
                                                                // display: flex;
                                                                // align-items: flex-end;
                                                                // flex-wrap: wrap;
                                                                .comment-content-span{
                                                                    word-wrap:break-word;
                                                                    word-break:break-all;
                                                                }
                                                                .text {
                                                                    font-size: 10px;
                                                                    color: #cccccc;
                                                                    margin-left: 10px;

                                                                    &.text-btn {
                                                                        cursor: pointer;
                                                                        color: #999999;
                                                                    }
                                                                }
                                                            }

                                                            .info-dianzan {
                                                                width: 20px;
                                                                position: absolute;
                                                                right: 0;
                                                                cursor: pointer;
                                                                top:0;
                                                                .icon-box {
                                                                    width: 20px;
                                                                    height: 20px;
                                                                    display: inline-block;
                                                                    position: relative;
                                                                    vertical-align: middle;
                                                                    line-height: 1;
                                                                    cursor: pointer;
                                                                    color: #999999;

                                                                    .icon-bg-box {
                                                                        position: absolute;
                                                                        top: 0;
                                                                        left: 0;
                                                                        width: 100%;
                                                                        height: 100%;
                                                                        overflow: hidden;
                                                                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                                                                        &.dianzan {
                                                                            background-image: url("../assets/like.svg");
                                                                            background-repeat: no-repeat;
                                                                            background-position: -10px -10px;
                                                                        }
                                                                    }

                                                                    .icon-def-box {
                                                                        position: relative;
                                                                        top: 0;
                                                                        left: 0;
                                                                        width: 20px;
                                                                        height: 20px;
                                                                        display: inline-block;
                                                                    }

                                                                    &.ismy {
                                                                        color: #f69c4a;
                                                                    }
                                                                }

                                                                .item-icon-tabs {
                                                                    position: absolute;
                                                                    right: 20px;
                                                                    top: 20px;
                                                                    cursor: pointer;
                                                                    color: #999;

                                                                    &.ismy {
                                                                        color: #f69c4a;
                                                                    }

                                                                    .icon-bg-box {
                                                                        position: absolute;
                                                                        top: 0;
                                                                        left: 0;
                                                                        width: 100%;
                                                                        height: 100%;
                                                                        overflow: hidden;
                                                                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                                                                        &.guanzhu {
                                                                            background-image: url("../assets/follow.svg");
                                                                            background-repeat: no-repeat;
                                                                            background-position: -17px -17px;
                                                                            background-size: 52px;
                                                                        }
                                                                    }

                                                                    .icon-def-box {
                                                                        position: relative;
                                                                        top: 0;
                                                                        left: 0;
                                                                        width: 20px;
                                                                        height: 20px;
                                                                        display: inline-block;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .comment-item-reply {
                                                            min-width: 200px;
                                                            font-size: 14px;
                                                            flex: 1;
                                                            overflow: hidden;
                                                            margin-left: 35px;
                                                            padding-top: 10px;

                                                            .reply-item-box {
                                                                margin-top: 2px;
                                                                padding-left: 10px;

                                                                .reply-item {
                                                                    display: flex;
                                                                    width: 100%;
                                                                    position: relative;
                                                                    margin-top: 10px;

                                                                    &:first-child {
                                                                        margin-top: 0;
                                                                    }

                                                                    .user-ava {
                                                                        width: 15px;
                                                                        height: 15px;
                                                                        margin-right: 10px;
                                                                        line-height: 1;
                                                                        .img-user {
                                                                            width: 15px;
                                                                            height: 15px;
                                                                            border-radius: 50%;
                                                                        }
                                                                    }

                                                                    .info-text {
                                                                        flex: 1;
                                                                        overflow: hidden;
                                                                        word-break: break-word;
                                                                        font-size: 14px;
                                                                        font-weight: 400;
                                                                        line-height: 19px;
                                                                        color: #333333;
                                                                        margin-right: 8%;
                                                                        // display: flex;
                                                                        // align-items: flex-end;
                                                                        // flex-wrap: wrap;
                                                                        .comment-content-span{
                                                                            word-wrap:break-word;
                                                                            word-break:break-all;
                                                                        }
                                                                        .text {
                                                                            font-size: 10px;
                                                                            color: #cccccc;
                                                                            margin-left: 10px;

                                                                            &.text-btn {
                                                                                cursor: pointer;
                                                                                color: #999999;
                                                                            }
                                                                        }
                                                                    }

                                                                    .info-dianzan {
                                                                        width: 20px;
                                                                        position: absolute;
                                                                        bottom: 0px;
                                                                        right: 0;
                                                                        cursor: pointer;
                                                                        display: none;
                                                                        .icon-box {
                                                                            width: 20px;
                                                                            /*height: 20px;*/
                                                                            display: inline-block;
                                                                            position: relative;
                                                                            vertical-align: middle;
                                                                            line-height: 1;
                                                                            cursor: pointer;
                                                                            color: #999999;

                                                                            .icon-bg-box {
                                                                                position: absolute;
                                                                                top: 0;
                                                                                left: 0;
                                                                                width: 100%;
                                                                                height: 100%;
                                                                                overflow: hidden;
                                                                                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                                                                                &.dianzan {
                                                                                    background-image: url("../assets/like.svg");
                                                                                    background-repeat: no-repeat;
                                                                                    background-position: -10px -10px;
                                                                                }
                                                                            }

                                                                            .icon-def-box {
                                                                                position: relative;
                                                                                top: 0;
                                                                                left: 0;
                                                                                width: 20px;
                                                                                height: 20px;
                                                                                display: inline-block;
                                                                            }

                                                                            &.ismy {
                                                                                color: #f69c4a;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .fold-btn {
                                                                    font-size: 12px;
                                                                    font-weight: 400;
                                                                    line-height: 19px;
                                                                    color: #cccccc;
                                                                    display: inline-block;
                                                                    margin-left: 25px;
                                                                    margin-top: 10px;
                                                                    cursor: pointer;
                                                                }
                                                            }
                                                        }

                                                        .line {
                                                            position: absolute;
                                                            height: 1px;
                                                            // background: #eeeeee;
                                                            left: 46px;
                                                            right: 0;
                                                            bottom: 0;
                                                        }
                                                    }
                                                }


                                                .list-loading {
                                                    display: flex;
                                                    align-items: center;
                                                    margin-top: 20px;
                                                    height: 40px;
                                                    justify-content: center;

                                                    > span {
                                                        display: inline-block;
                                                        width: 16px;
                                                        height: 16px;
                                                        margin-right: 6px;
                                                        border-radius: 50%;
                                                        background: #ebebeb;
                                                        -webkit-animation: load 1.04s ease infinite;

                                                        &:last-child {
                                                            margin-right: 0px;
                                                        }
                                                    }

                                                    @-webkit-keyframes load {
                                                        0% {
                                                            opacity: 1;
                                                        }
                                                        100% {
                                                            opacity: 0;
                                                        }
                                                    }

                                                    span:nth-child(1) {
                                                        -webkit-animation-delay: 0.13s;
                                                    }

                                                    span:nth-child(2) {
                                                        -webkit-animation-delay: 0.26s;
                                                    }

                                                    span:nth-child(3) {
                                                        -webkit-animation-delay: 0.39s;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }

                            }
                        }
                    }

    #dialog {
        border-radius: 20px 20px 0 0;
        // position: fixed;
        bottom: 0;
        //background-color: #FFFFFF;
        width: 100vw;
        padding-bottom: 16px;
        .comment-cross {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 5555;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

    }

    /* 适配电脑等大屏幕 */
    @media (min-width: 100px) {
        #dialog {
            width: 100%;
            max-width: 840px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .comment-text-div {
            padding: 5px 5px 20px;
            // height: auto;
            background: #F9F9F9;
            // border-radius: 8px;
            overflow: hidden;
            position: relative;
            height: auto;

            .comment-text {
                width: 100%;
                height: auto;
                resize: none;
                border: none;
                font-size: 8px;
                line-height: 19px;
                background: none;
                outline: none;
            }
            .btn-emoticons{
                /*position: absolute;*/
                float: left;
                left: 20px;
                bottom: 20px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                .icon-emoticons{
                    display: flex;
                    align-items: center;
                    span{
                        margin-left: 6px;
                        font-size: 15px;
                    }
                }
            }
            .btn-box {
                position: absolute;
                float: right;
                bottom: 2px;
                right: 2px;

                > button {
                    width: 60px;
                    background: #EEEEEE;
                    border-radius: 4px;
                    font-size: 1px;
                    font-weight: 300;
                    /* line-height: 34px; */
                    color: #CCCCCC;
                    border: none;
                    outline: none;
                    margin-left: 15px;
                    cursor: pointer;

                }
            }
        }
    }

    .dialog-button {
        width: 100%;
        background-color: #6367D5;
        border-width: 0;
        border-radius: 360px;
        padding: 10px;
        outline: none;
        color: white;
    }

    .dialog-button:focus {
        outline: none;
    }

    .dialog-button:active {
        background-color: #585dbe;
        border-color: #585dbe;
    }

    .dialog-enter-active, .dialog-leave-active {
        transition: all .5s;
    }

    .dialog-enter, .dialog-leave-to {
        opacity: 0;
        transform: translateY(300px);
    }
                    @media (max-width: 840px) {
                        .detail-box .container {
                            width: 100%;
                            padding: 0 10px;
                        }
                        .detail-box .container .container-c {
                            width: 100%;
                            display: block;
                            min-width: 1200px;

                            .container-c-left {
                                // min-width: 530;
                                width: 100%;
                                height: 70vh;
                                margin-bottom: 5px;
                                .item-list {
                                    width: 100%;
                                    height: 100%;
                                    .item-list-imgbox {
                                        width: 100%;
                                        height: 100%;
                                        .item-list-imgbox-center {
                                            width: 100%;
                                            height: 100%;
                                            .item-list-img-info {
                                                object-fit: contain;
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }
                            }

                            .container-c-right {
                                margin-left: 0px;
                                width: 100%;
                                height: 100vh;
                                max-width: 560px;
                                min-width: 560px;
                                /*height: 100%;*/
                                flex: 1;

                                .container-right-box {
                                    width: 100%;
                                    position: relative;
                                    top: 0;
                                    padding-left: 0;

                                    .item-center {
                                        width: 100%;
                                        height: 100%;
                                        .comment-box {
                                            .tab-box {
                                                min-width: 430px;
                                                .tab-item-box {
                                                    min-width: 230px;
                                                    .tab-item {
                                                        color: #cccccc;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        line-height: 24px;
                                                        margin-right: 18px;
                                                        display: inline-block;
                                                        cursor: pointer;

                                                        &.active {
                                                            color: #333333;
                                                            font-weight: bold;
                                                            border-bottom: 2px solid #0870d1;
                                                            padding-bottom: 9px;
                                                        }
                                                    }
                                                }
                                                .filter-item {
                                                    > span {
                                                        margin-left: 15px;
                                                        font-size: 12px;
                                                        font-weight: 400;
                                                        line-height: 16px;
                                                        color: #666666;
                                                        cursor: pointer;

                                                        &.active {
                                                            color: #0870d1;
                                                        }

                                                        > svg {
                                                            margin-right: 2px;
                                                            position: relative;
                                                            top: 1px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .detail-box .container .item-center {
                            width: 100%;
                        }

                        .detail-box .container .container-c .container-c-left .item-list {
                            .item-icon-tabs {
                                display: none;
                            }

                            .item-info {
                                display: block;

                                .info-right {
                                    .icon-box {
                                        margin-left: 0;
                                        margin-right: 25px;
                                    }

                                    .item-icon-tabs {
                                        display: block;
                                        position: absolute;
                                        right: 0;
                                        top: 2px;
                                    }
                                }
                            }
                        }

                        .detail-box .container .item-center .comment-box {
                            .tab-box {
                                .tab-item-box {
                                    .tab-item {
                                        margin-right: 20px;
                                    }
                                }

                                .filter-item {
                                    > span {
                                        margin-left: 12px;
                                    }
                                }
                            }
                            .comment-list-box {
                                .comment-item {
                                    display: block;
                                    display: flex;
                                    position: relative;
                                    padding-bottom: 20px;
                                    flex-direction: column;

                                    .comment-item-info {
                                        width: 100% !important;
                                    }

                                    .comment-item-reply {
                                        margin-top: 12px;

                                        .reply-item-box {
                                            padding-top: 12px;
                                            border-left: none !important;
                                            border-top: 1px solid #e6e3e3;
                                        }
                                    }
                                }
                            }
                        }
                        .item-center {
                            .comment-box {
                                .comment-text-div {
                                    .btn-box {
                                        > button {
                                            width: 45px;
                                            height: 32px;
                                            background: #eeeeee;
                                            border-radius: 4px;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 34px;
                                            color: #cccccc;
                                            border: none;
                                            outline: none;
                                            margin-left: 8px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
.link {
       float: left;
       margin-top: 6px;
       font-size: 14px;
       color: #4D5156;
    }
    .embed {
        background: #F48234;
        border-radius: 4px;
        margin-left: 16px;
        border-color: unset;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .copy {
       background: #2C8CF0;
       border-radius: 4px;
       margin-left: 16px;
       border-color: unset;
       font-size: 14px;
       color: #FFFFFF;
       cursor: pointer;
    }
.searchTitle {
        margin-top: 3px;
        float: left;
        font-size: 14px;
        color: #0870D1;
    }
    .searchImg {
        margin-left: 10px;
        cursor: pointer;
    }
    textarea::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #cccccc;
    }
</style>
