<template>
  <header
    class="header-box"
    :style="style"
  >
    <div class="header-left">
<!--      20210930 ‘三’形状按钮功能： 后台功能开发中，前端后续需要继续开发，暂且注释-->
<!--      <div-->
<!--        class="icon-side"-->
<!--        @click="targetSide"-->
<!--      >-->
<!--        <img-->
<!--          class="img-side"-->
<!--          :src="require('@/assets/icon-side.svg')"-->
<!--          alt=""-->
<!--        >-->
<!--      </div>-->
      <div
        class="icon-logo"
        @click="goIndex"
      >
        <img
          class="img-logo"
          :src="require('@/assets/logo.svg')"
          alt=""
        >
      </div>
    </div>
    <div class="header-center">
      <div
        class="header-tab"
        v-if="tabs && tabs.length>0"
      >
        <div
          v-for="item in tabs"
          @click="changeTab(item)"
          :key="item.tagId"
          class="tab-item"
          :class="item.tagId==selectTab?'active':''"
        >{{item.tagName}}<span v-if="this.list != null">({{ item.tagId == 0 ? list.suggestionCnt : (item.tagId == 1 ? list.questionCnt : list.commentCnt) }})</span>
        </div>
      </div>
      <div
        class="search-box"
        v-if="showSearch"
        @keydown=""
        :style="{
                        width: searchWidth,
                        // borderBottomLeftRadius: showSearchList?0:'25px',
                        // borderBottomRightRadius: showSearchList?0:'25px',
                 }"
      >
        <img
          class="search-icon"
          :src="require('@/assets/icon-search.svg')"
          alt=""
        >
        <input
          :value="value"
          class="search-input"
          :placeholder="$t('header.search')"
          @keydown.enter="keydownEnter"
          @input="changeSearchVal($event)"
          @focus="inputFocusHandle"
          @blur="inputBlurHandle"
        />
        <a-Button v-if="value" type="text" shape="circle" @click="value=''" >
          <div style="font-size:x-large;margin-top: -10px;color: #bbbbbb" >×</div>
        </a-Button>
        <div
          class="search-list"
          v-if="showSearchList"
        >
          <div
            class="search-list-item"
            v-for="item in searchList"
            :key="item"
            @click="changeSearchList(item)"
          >
            <HightLight
              :role_text="value"
              :text="item"
            />
          </div>
        </div>
      </div>

    </div>
    <div class="header-right">
      <a-dropdown
        placement="bottomCenter"
        v-if="!(tabs && tabs.length>0)"
        :overlay-style="{width:'112px'}"
      >
        <!-- <div style="margin-right: 15px;cursor: pointer;">{{$i18n.locale==='zh'?'中文':$i18n.locale==='ja'?'日本語':'English'}}</div>
        <template #overlay>
          <a-menu>
            <a-menu-item
              key="zh"
              @click="change('zh')"
            >中文</a-menu-item>
            <a-menu-item
              key="ja"
              @click="change('en')"
            >English</a-menu-item>
            <a-menu-item
              key="en"
              @click="change('ja')"
            >日本語</a-menu-item>
          </a-menu>
        </template> -->
      </a-dropdown>
      <div
        class="icon-add"
        @click="userInfo ? openCreate() :login()"
      >
        <img
          class="img-add"
          :src="require('@/assets/add.svg')"
          alt=""
        >
      </div>
      <a-dropdown
        placement="bottomCenter"
        :overlay-style="{width:'112px'}"
        v-if="userInfo"
      >
        <div class="icon-user">
          <img
            class="img-user"
            :src="userInfo.avatar ? userInfo.avatar : require('@/assets/def-ava.svg')"
            alt=""
          >
        </div>
        <template #overlay>
          <a-menu @click="changeMenu">
            <a-menu-item key="my">{{$t('header.personal')}}</a-menu-item>
            <a-menu-item key="setting">{{$t('header.account')}}</a-menu-item>
            <a-menu-item key="changeUser">{{$t('header.switchAccount')}}</a-menu-item>
            <a-menu-item key="exit">{{$t('header.exit')}}</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <div
        class="icon-user"
          v-else
        @click="login()"
      >
        <img
          class="img-user"
          :src="require('@/assets/def-ava.svg')"
          alt=""
        >
      </div>
    </div>
  </header>
  <Login
    :visible="visible"
    :closeModal="closeModal"
  />
</template>

<script>
import Login from '@/views/Login.vue'
import HightLight from '@/components/HightLight.vue'

import Api from '@/Api'

let searchTimer = null
export default {
  name: 'Header',
  props: ['style', 'showSearch', 'tabs', 'selectTab', 'value', 'entryId', 'entryName', 'noneToSearch','list','searchBox'],
  emits: ['targetSide', 'keydownEnter', 'changeTab', 'keydownEnter', 'changeSearchEvent','list'],
  components: {
    Login,
    HightLight
  },
  data () {
    return {
      showSearchList: false, //显示搜索列表
      searchList: [], //搜索列表
      activeSearch: '', //搜索列表选中

      visible: false,
      userInfo: localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')) || null,
    }
  },
  computed: {
    searchWidth () {
      return this.tabs && this.tabs.length > 0 ? '440px' : '840px'
    }
  },
  methods: {
    change (type) {
      this.$i18n.locale = type
      localStorage.setItem("language", type)
    },
    closeModal () {
      this.visible = false;
      this.userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')) || null;
    },
    targetSide () {
      this.$emit('targetSide');
    },
    goIndex () {
      this.$router.push('/')
    },
    changeTab (val) {
      this.$emit('changeTab', val);
    },
    changeMenu (item) {
      let value = item.key;
      if (value == 'my') {
        this.$router.push({ name: 'my' })
      }
      if (value == 'setting') {
        this.$router.push({ name: 'setting' })
      }
      if (value == 'changeUser') {
        this.visible = true;
      }
        if (value == 'exit') {
            localStorage.setItem("token",'');
            localStorage.setItem("userInfo",JSON.stringify(null));
            if (this.$route.name === 'Index') {
                location.reload();
            } else {
                this.$router.push({ name: 'Index' })
            }
        }
    },
    login () {
      this.visible = true;
    },


    keydownEnter () {
      this.$emit('keydownEnter');
      this.showSearchList = false;
    },
    changeSearchVal (e) {
      // this.showSearchList = e.target.value ? true : false
      if (!e.target.value) {
        // 如果数据被清空
        if (localStorage.getItem('token')) {
          // todo 如果已经登录,调用接口获取用户历史数据
          this.searchList=[]
        } else {
          // 未登录,显示本地数据
          setTimeout(() => {
            this.searchList = JSON.parse(localStorage.getItem('searchHistory'))
          }, 200)
        }
      }
      this.$emit('changeSearchEvent', e.target.value);
      this.setSearchList();
    },

    //搜索框选中
    changeSearchList (val) {
      Api.modifyUserSearchHistory(val.id, val.title)
      if (!localStorage.getItem('token')) {
      //   Api.modifyUserSearchHistory(val.id, val.title)
      // } else {
        // 保存到本地
        let oldHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
        if (val) {
          if (oldHistory.indexOf(val)>-1) {
            oldHistory.splice(oldHistory.indexOf(val),1)
          }
          oldHistory.unshift(val);
          if (oldHistory.length > 10) {
            oldHistory.splice(10)
          }
          localStorage.setItem("searchHistory", JSON.stringify(oldHistory));
        }
      }
      this.$emit('changeSearchEvent', val);
      this.setSearchList();
      this.$emit('keydownEnter');
    },
    //绑定按键
    keyDown (e) {
      let e1 = e || event || window.event
      if (e1 && e1.keyCode == 13) {
        if (!this.noneToSearch) {
          this.toSearchPage();
        }

      }
      //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
      if (this.showSearchList) {
        if (!this.activeSearch) {
          this.activeSearch = this.searchList[0];
        }
        let index = this.searchList.indexOf(this.activeSearch);
        if (e1 && e1.keyCode == 38) {
          index--
          if (index < 0) {
            index = 0
          }

          this.activeSearch = this.searchList[index];
          this.$emit('changeSearchEvent', this.activeSearch);
        } else if (e1 && e1.keyCode == 40) {
          index++
          if (index > this.searchList.length - 1) {
            index = this.searchList.length - 1
          }

          this.activeSearch = this.searchList[index];
          this.$emit('changeSearchEvent', this.activeSearch);
        }

      }
    },

    setSearchList () {
      if (this.value && this.value.trim() != '') {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          if (this.value) {
            Api.getSearch({
              keywords: this.value
            }).then((res) => {
              let data = res.result.data.map((item) => item.title);
              if (data.length > 0) {
                this.searchList = data
              }
            });
          }
        }, 200)

      } else {
        this.searchList = []
      }

    },


    inputFocusHandle () {
      this.showSearchList = true
      if (this.value) {
        this.setSearchList();
      } else {
        if (localStorage.getItem('token')) {
          // todo 如果已经登录,调用接口获取用户历史数据
          this.searchList=[]
        } else {
          // 未登录,显示本地数据
          setTimeout(() => {
            this.searchList = JSON.parse(localStorage.getItem('searchHistory'))
          }, 200)
        }
      }
    },
    inputBlurHandle () {
      setTimeout(() => {
        this.showSearchList = false
      }, 200)
    },

    openCreate () {
      // this.$router.push('/create')
      // this.$router.push({name: 'create', query: {entryId: this.entryId, entryName: this.entryName}})
      let routeData = this.$router.resolve({
        path: '/create',
        query: { entryId: this.entryId, entryName: this.entryName }
      });
      window.open(routeData.href, '_blank');
    },
  }
}

</script>
<style scoped lang="scss">
.header-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(226, 234, 242, 0.4);
  position: fixed;
  top: 0;
  z-index: 2;

  .header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon-side {
      margin-left: 40px;
      width: 18px;

      .img-side {
        width: 18px;
        height: 16px;
        cursor: pointer;
      }
    }

    .icon-logo {
      margin-left: 40px;
      width: 32px;
      height: 32px;

      .img-logo {
        margin-top: 3px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }

  .header-center {
    max-width: 1300px;
    /*width: 1300px;*/
    display: flex;
    align-items: center;
    /*overflow: hidden;*/
    position: absolute;
    left: 200px;
    right: 200px;

    .header-tab {
      flex: 1;
      line-height: 58px;
      overflow-x: auto;
      padding: 0 20px;
      text-align: center;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
      .tab-item {
        white-space: nowrap;
        display: inline-block;
        // margin: 0 31px;
        margin: 13px 131px 13px 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #666666;
        cursor: pointer;
        vertical-align: middle;

        &.active {
          color: #0870d1;
          font-weight: bold;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -12px;
            width: 100%;
            height: 4px;
            background: #0870d1;
          }
        }
      }
    }

    .search-box {
      width: 440px;
      /*width: 840px;*/
      height: 32px;
      background: #ffffff;
      border: 1px solid #dfe1e5;
      border-radius: 25px;
      padding: 0 21px;
      display: flex;
      align-items: center;
      z-index: 1;
      position: relative;

      .search-icon {
        width: 14px;
        height: 14px;
      }

      .search-input {
        flex: 1;
        height: 14px;
        margin-left: 21px;
        font-size: 14px;
        font-weight: 400;
        border: none;
        outline: none;
        background: none;
      }

      .search-list {
        border-radius: 0 0 16px 16px;
        width: 100%;
        height: 380px;
        background: #fff;
        position: absolute;
        top: 32px;
        left: 0;
        padding-bottom: 16px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
        .search-list-item {
          font-size: 14px;
          font-weight: bold;
          color: #666666;
          line-height: 1;
          padding: 0 20px;
          margin-top: 16px;
          cursor: pointer;
          &.active {
            color: #0870d1;
          }
        }
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon-user {
      margin-right: 40px;
      width: 34px;
      height: 34px;

      .img-user {
        width: 34px;
        height: 34px;
        cursor: pointer;
        border-radius: 50%;
      }
    }

    .icon-add {
      margin-right: 40px;
      width: 34px;
      height: 34px;

      .img-add {
        width: 34px;
        height: 34px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 840px) {
  .header-box {
    .header-left {
      .icon-side {
        margin-left: 20px;
      }
      .icon-logo {
        margin-left: 20px;
      }
    }
    .header-right {
      .icon-user {
        margin-right: 20px;
      }
      .icon-add {
        margin-right: 20px;
      }
    }
    .header-center {
      left: 90px;
      right: 90px;
      .header-tab {
        /*display: none;*/
      }
      .tab-item{
        margin-left:13px 50px 13px 0
      }
      .search-box {
        display: none;
      }
    }
  }
}
</style>
