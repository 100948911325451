<template>
    <Header @targetSide="targetSide"
            :showSearch="true"
            :value="condition.keyword"
            :noneToSearch="true"
            @keydownEnter="keydownEnter"
            @changeSearchEvent="changeSearchEvent"
    />
    <div class="container-bg-white">
        <div class="container">
            <div class="search-result-content">
                <!--切换用tab-->
                <ul class='tab-list'>
                    <li v-for="(item,index) in tabsList" :key="index" @click="changeTab2(item)"
                        :class="tab==item.value ? 'active':''">
                        <img :src="item.icon" alt="">
                        {{ item.label }}
                    </li>
                </ul>
                <!--todo   切换成图片隐藏该div,切换成全部或者文本,显示该div-->
                <div class="info-div" v-if="tab == 'all'">
                    <div class="result-nums">{{$t('searchList.about')}} {{condition.totalSize}} {{$t('searchList.results')}}</div>
                    <!--todo 切换成文本,隐藏该div,并调用文本接口,切换成全部,才显示改div-->
                    <div class="result-info-div">
                        <div class='res-item' style="cursor: pointer;" v-if="condition.page == 2 && condition.list.length > 0"
                             @click="goInDetail(condition.list[0].id, condition.list[0].title, condition.list[0].lastPicture?.pictureId)">
                            <div class="item-img">
                                <img :src="condition.list[0].lastPicture && condition.list[0].lastPicture.pictureUrl ? condition.list[0].lastPicture.pictureUrl : require('@/assets/images/img.png')"
                                     alt="">
                            </div>
                            <div class='item-bottom'>
                                <p class="item-name">{{ condition.list[0].title }}</p>
                                <div class="item-info line-clamp3">
                                    <!-- {{ condition.list[0].lastPicture?.note || condition.list[0].lastPicture?.comment }} -->
                                    <span v-html=" condition.list[0].lastPicture?.note || condition.list[0].lastPicture?.comment "></span>
                                </div>
                                <div class="item-time">{{ condition.list[0].lastPicture && condition.list[0].lastPicture.pictureTime ?
                                    getWaitDurationTime(condition.list[0].lastPicture?.pictureTime) : "" }}
                                </div>
                            </div>
                        </div>
                        <div class='res-item' style="cursor: pointer;" v-if="condition.page == 2 && condition.list.length > 1"
                             @click="goInDetail(condition.list[1].id, condition.list[1].title, condition.list[1].lastPicture?.pictureId)">
                            <div class="item-img">
                                <img :src="condition.list[1].lastPicture && condition.list[1].lastPicture.pictureUrl ? condition.list[1].lastPicture.pictureUrl : require('@/assets/images/img.png')"
                                     alt="">
                            </div>
                            <div class='item-bottom'>
                                <p class="item-name">{{ condition.list[1].title }}</p>
                                <div class="item-info line-clamp3">
                                    <!-- {{ condition.list[1].lastPicture?.note || condition.list[1].lastPicture?.comment }} -->
                                    <span v-html="condition.list[1].lastPicture?.note || condition.list[1].lastPicture?.comment"></span>
                                </div>
                                <div class="item-time">{{ condition.list[1].lastPicture && condition.list[1].lastPicture.pictureTime ?
                                    getWaitDurationTime(condition.list[1].lastPicture?.pictureTime) : "" }}
                                </div>
                            </div>
                        </div>
                        <div class='res-item' style="cursor: pointer;" v-if="condition.page == 2 && condition.list.length > 2"
                             @click="goInDetail(condition.list[2].id, condition.list[2].title, condition.list[2].lastPictrue?.pictureId)">
                            <div class="item-img">
                                <img :src="condition.list[2].lastPicture && condition.list[2].lastPicture.pictureUrl ? condition.list[2].lastPicture.pictureUrl : require('@/assets/images/img.png')"
                                     alt="">
                            </div>
                            <div class='item-bottom'>
                                <p class="item-name">{{ condition.list[2].title }}</p>
                                <div class="item-info line-clamp3">
                                    <span v-html="condition.list[2].lastPicture?.note || condition.list[2].lastPicture?.comment"></span>
                                    {{ condition.list[2].lastPicture?.note || condition.list[2].lastPicture?.comment }}
                                </div>
                                <div class="item-time">{{ condition.list[2].lastPicture && condition.list[2].lastPicture.pictureTime ?
                                    getWaitDurationTime(condition.list[2].lastPicture?.pictureTime) : "" }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="result-list"
                        v-if="(condition.page == 2 && condition.list.length > 3) || (condition.page > 2)">
                        <li v-for="(entry, i) in condition.list" @click="goInDetail(entry.id, entry.title, entry.lastPicture?.pictureId)">
                            <p class="item-name" v-if="(condition.page == 2 && i > 2) || (condition.page > 2)">
                                {{ entry.title }}</p>
                            <div class="item-info line-clamp1"
                                 v-if="(condition.page == 2 && i > 2) || (condition.page > 2)">
                                {{ entry.lastPicture?.note || entry.lastPicture?.comment }}
                            </div>
                            <div class="item-time" v-if="(condition.page == 2 && i > 2) || (condition.page > 2)">
                                {{ entry.lastPicture && entry.lastPicture.pictureTime ? getWaitDurationTime(entry.lastPicture?.pictureTime) : "" }}
                            </div>
                        </li>
                    </ul>
                    <div class="more-div">
                        <div class="title">{{$t('searchList.relatedSearches')}}</div>
                        <table class="table-box">
                            <tr v-for="items in moreData">
                                <td v-for="item in items.data">
                                    <div class="table-td line-clamp1" @click="selectHotSearch(item)">
                                        <img :src="require('@/assets/icon-search.svg')" alt="">
                                        <span>{{ item.entryName }}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="pagination-div">
                        <a-pagination
                                :hideOnSinglePage="true"
                                :current="condition.page-1"
                                :page-size="condition.pageSize"
                                :total="condition.totalSize"
                                show-less-items
                                @change="onChangePage"
                        />
                    </div>
                </div>
                <!--todo 切换图片,显示图片的div-->
                <div v-if="tab == 'pic'" class="img-div"  @scroll="scrollEvent">
                     <div class="result-nums">{{$t('searchList.about')}} {{condition.imgTotalSize}} {{$t('searchList.results')}}</div>
                    <!--todo,预留的写图片的页面的位置-->
                    <!-- tab值点击图片时切换到图片的div 其他页面隐藏显示图片的div -->
                    <div class="img-item-info">
                    <div class="img-item" v-for="(entry, i) in condition.pList" @click="getPictureEntryId(entry.pictureId)">
                         <img :src="entry.pictureUrl" alt="">
                         <div  class="title">
                            <p>{{entry.entryTitle}}</p>
                         </div>
                         <div class="title-time">
                             <p class="time">{{getWaitDurationTime(entry.insertTime)}}</p>
                              <p class="time">{{entry.insertTime}}</p>
                         </div>
                    </div>
                    </div>
                    <Loading :loading="loading"/>
                </div>
                <!-- 切换文本位置 -->
                <div v-if="tab == 'text'" class="text-info-div" @scroll="scrollEventText">
                     <div class="result-nums">{{$t('searchList.about')}} {{condition.textTotalSize}} {{$t('searchList.results')}}</div>
                    <div class="test-div">
                      <ul>
                          <li class="text-div-li" v-for="(entry, i) in condition.pText" :key="i">
                              <div class="text-top"><img :src="entry.pictureUrl" alt=""><p>{{entry.entryTitle}}</p></div>
                              <div class="text-middle">
                                  <p class="text-title">{{entry.entryTitle}}</p>
                                  <p class="text-content">{{entry.comment}}</p>
                                  <p class="text-time">{{getWaitDurationTime(entry.insertTime)}}</p>
                              </div>
                          </li>
                      </ul>
                      </div>
                      <Loading :loading="TextLoading"/>
                </div>
            </div>
        </div>
    </div>
    <SideNav :showSide="showSide"/>
    <Footer/>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue';
import Api from '@/Api/index'
import moment from "moment";
import {trimParams, urlParamsParse} from '@/utils/common';
import {getWaitDurationTime} from '@/utils/common';
import Loading from '@/components/Loading.vue'
import Login from "@/views/Login";

export default {
    name: "SearchList",
    components: {
        Header,
        Footer,
        SideNav,
        Loading
    },
    computed: {
        tabsList: function () {
            return [
                {
                    value: "all",
                    label: this.$t('searchList.all'),
                    icon: require('@/assets/icon-all.svg')
                },
                {
                    value: "pic",
                    label: this.$t('searchList.picture'),
                    icon: require('@/assets/icon-image.svg')
                },
                {
                    value: "text",
                    label: this.$t('searchList.text'),
                    icon: require('@/assets/icon-news.svg')
                },
                // {
                //     value: "news",
                //     label: this.$t('searchList.news'),
                //     icon: require('@/assets/icon-news.svg')
                // }
            ]
        }
    },

    data() {
        return {
            showSide: false,
            tabs: ['推荐', '热点', '关注'],
            selectTab: '推荐',
            tab: 'all',
            TextLoading:false,

            condition: {
                keyword: urlParamsParse() && urlParamsParse().keywords || '',
                page: 1,
                totalPage: 1,
                pageSize: 20,
                totalSize:0,
                list: [],
                pList:[],
                pText:[],
                // img
                imgPage: 1,
                imgTotalPage:1,
                imgSize:60,
                imgTotalSize:0,
                // text
                textPage:1,
                textTotalPage:1,
                textSize:30,
                textTotalSize:0,
                entryId:0,

            },
            moreData: [],
        }
    },
    mounted() {
        if (!localStorage.getItem('language')) {
            localStorage.setItem('language','ja')
        }
        this.$i18n.locale = localStorage.getItem('language')

        this.handleData()
        this.searchEntries();
        this.searchPictures();
        this.searchText();

    },
    methods: {
        selectHotSearch (val, isSaveHistory = true) {
            this.condition.keyword = val.entryName;
            if (isSaveHistory) {
                this.keydownEnter()
            }
        },
        getWaitDurationTime(start, end = moment()) {
          let timeZone = new Date().getTimezoneOffset()/60
          // console.log('时区', new Date().getTimezoneOffset()/60)
          if (typeof timeZone !== "number") {
            return new Date();
          }
            if (start, timeZone) {
              let startTime = moment(start);
              let newStart = new Date(startTime - 3600000 * timeZone);
              let newTime = moment(newStart);
              let endTime = moment(end);
              if (newTime.isSame(end)) {
                return ''
              }

                let waitTime = moment.duration(endTime - newTime, 'ms');
                let years = waitTime.get('years');
                let months = waitTime.get('months');
                let days = waitTime.get('days');
                let hours = waitTime.get('hours');
                let minutes = waitTime.get('minutes');

                if (!years && !months && !days && !hours) {
                    if (minutes >= 1) {
                        return minutes + this.$t('time.minutesAgo')
                    } else {
                        return this.$t('time.justNow')
                    }
                } else if (!years && !months && !days && hours) {
                    if (hours > 1) {
                        return hours + this.$t('time.hoursAgo')
                    } else {
                        return hours + this.$t('time.hourAgo')
                    }
                } else if (!years && !months && days) {
                    if (days > 1) {
                        return days + this.$t('time.daysAgo')
                    } else {
                        return days + this.$t('time.dayAgo')
                    }
                } else if (!years && months) {
                    if (months > 1) {
                        return months + this.$t('time.monthsAgo')
                    } else {
                        return months + this.$t('time.monthAgo')
                    }
                } else if (years) {
                    if (years > 1) {
                        return years + this.$t('time.yearsAgo')
                    } else {
                        return years + this.$t('time.yearAgo')
                    }
                } else {
                    return moment(newTime).format('YYYY.M.D HH:mm:ss')
                }

            }
            return ''
        },
        handleData() {
            Api.findHotSearch().then((res) => {
                if (res.error_code == 0) {
                    let dataRes = [];
                    let obj = {
                        data: [],
                    };
                    let data = res.result.data
                    let len = data.length;
                    data.forEach((item, index) => {
                        obj.data && obj.data.push(item);
                        if (index % 2 == 1) {
                            dataRes.push(obj);
                            obj = {data: []};
                        }

                        if (len - 1 == index && index % 2 != 1) {
                            dataRes.push(obj);
                        }
                    })
                    this.moreData = dataRes
                }
            });
        },

        //Header
        targetSide() {
            this.showSide = !this.showSide
        },
        keydownEnter() {
            this.condition = {
                ...this.condition,
                page: 1,
                totalPage: 1,
                pageSize: 20,
                totalSize:0,
                list: [],
                pList:[],
                pText:[],
                imgPage: 1,
                imgTotalPage:1,
                imgTotalSize:0,
                imgSize:60,
                textPage:1,
                textTotalPage:1,
                textTotalSize:0,
                textSize:30,

            }
            this.searchEntries();
            this.searchPictures();
            this.searchText();
        },
        changeSearchEvent(val) {
            this.condition.keyword = val;
            // this.getSearch();
        },

        setSearchList() {
        },
        searchEntries() {
            if (this.condition.totalPage < this.condition.page) {
                return;
            }

            Api.searchEntries(this.condition.keyword, undefined, this.condition.page, this.condition.pageSize).then((res) => {
                if (res.error_code == 0) {
                    let data = res.result.data;
                    this.condition.list = data.list;
                    this.condition.totalPage = data.totalPageCount;
                    this.condition.totalSize = data.totalSize;
                    this.condition.page++;
                    // 第一页的结构，保存到本地，供上传页面使用
                    if (this.condition.page === 1 || data.list.length > 0) {
                        localStorage.setItem("selectItemList",JSON.stringify(data.list))
                    }
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }

            }).catch(err => {
                console.log("Get Entry Page ==> ", err)
            }).finally(() => {
                this.loading = false
            });
        },


        changeTab2(item) {
            this.tab = item.value;
        },
        onChangePage(page) {
            this.condition.page = page
            this.searchEntries()
        },


        scrollEvent(event){
            let clientHeight = document.documentElement.clientHeight;
            let scrollTop = event.target.scrollTop || document.documentElement.scrollTop;
            let num = clientHeight - 150;
            let headerStyle = {};
            if (scrollTop > num) {
                let tNum = (scrollTop - num) - 52;
                if (tNum >= 0) {
                    tNum = 0
                }
                headerStyle = {
                    transform: `translateY(${tNum}px)`
                }

            } else {
                headerStyle = {
                    transform: `translateY(-52px)`
                }
            }
            this.headerStyle = headerStyle;
            if (!this.loading) {
                if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
                    this.loading = true;
                    this.isTop = false;
                    this.getList();
                }
            }
      },

        scrollEventText(event){
            let clientHeight = document.documentElement.clientHeight;
            let scrollTop = event.target.scrollTop || document.documentElement.scrollTop;
            let num = clientHeight - 150;
            let headerStyle = {};
            if (scrollTop > num) {
                let tNum = (scrollTop - num) - 52;
                if (tNum >= 0) {
                    tNum = 0
                }
                headerStyle = {
                    transform: `translateY(${tNum}px)`
                }

            } else {
                headerStyle = {
                    transform: `translateY(-52px)`
                }
            }
            this.headerStyle = headerStyle

            if (!this.TextLoading) {
                if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
                    this.TextLoading = true;
                    this.isTop = false;
                    this.getList2();
                }
            }
      },
        getList() {
                this.tagId = this.selectTab;
                this.searchPictures(this.tagId);

        },
          getList2() {
                this.tagId = this.selectTab;
                this.searchText(this.tagId);

        },
        // 获取图片接口
        searchPictures(tagId) {
            if (this.condition.imgTotalPage < this.condition.imgPage) {
                return;
            }
             if (this.condition.keyword && this.condition.keyword.trim() != '') {
            Api.searchPictures(undefined,this.condition.keyword,this.condition.imgPage -1, this.condition.imgSize).then((res) => {
                let data = res.result.data;
                this.condition.imgTotalPage = data.totalPageCount;
                this.condition.imgTotalSize = data.totalSize;
                this.condition.imgPage++;
                this.loading = true;
                this.condition.pList = this.condition.pList.concat(data.list);
                 if (this.condition.imgPage === 1 || data.list.length > 0) {
                        // localStorage.setItem("selectItemList",JSON.stringify(data.list))
                    }
                document.body.scrollTop == 0;
                document.documentElement.scrollTop == 0;
                event.target.scrollTop = 0;

            }).catch(err => {
                console.log("Get Entry Page ==> ", err)
            }).finally(() => {
                this.loading = false
            });
             }else{
                 this.pList = [];
             }
        },
        getPictureEntryId(pictureId){
           Api.getPicture(pictureId).then(res =>{
            if (res.error_code == 0) {
            let data = res.result.data;
            this.condition.entryId = data.entryId;
            this.goToInDetail(this.condition.entryId,data.entryTitle,data.pictureId);
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
            if (err.response.status == 401 || err.response.status == 400) {
                this.$message.error(this.$t("tokenErrMessage"));
                this.visible = true
            }
        }).finally(() => {
          this.loading = false
        })
        },

        goInDetail(entryId, entryTitle, pictureId) {
            Api.saveHistory(entryId, entryTitle, pictureId);
            window.open(`/${entryId}`)
        },
         goToInDetail(entryId, entryTitle, pictureId) {
            Api.saveHistory(entryId, entryTitle, pictureId);
            window.open(`/toDetail/${entryId}/${pictureId}`)
        },
        // 获取文本接口
        searchText(tagId) {
            if (this.condition.textTotalPage < this.condition.textPage) {
                return;
            }
             if (this.condition.keyword && this.condition.keyword.trim() != '') {
            Api.searchPictures(undefined,this.condition.keyword,this.condition.textPage -1, this.condition.textSize).then((res) => {
                let data = res.result.data;
                this.condition.textTotalPage = data.totalPageCount;
                this.condition.textTotalSize = data.totalSize;
                this.condition.textPage++;
                this.TextLoading = false
                this.condition.pText = this.condition.pText.concat(data.list);
                 if (this.condition.textPage === 1 || data.list.length > 0) {
                        console.log(data.list.map(value => value.entryTitle))
                        // localStorage.setItem("selectItemList",JSON.stringify(data.list))
                    }
                document.body.scrollTop == 0;
                document.documentElement.scrollTop == 0;
                event.target.scrollTop = 0;

            }).catch(err => {
                console.log("Get Entry Page ==> ", err)
            }).finally(() => {
                this.loading = false
            });
             }else{
                 this.pText = [];
             }
        },
    }
}
</script>

<style scoped lang="scss">
.container-bg-white {
    min-height: calc(100vh);
    width: 100%;
}
.container {
    width: 100%;
.search-result-content {
    width: 100%;
    margin-top: 52px;
    padding-bottom: 90px;
      .info-div{
         width: 1300px;
         margin: 0 auto;
         margin-left: 17%;
    }
    .tab-list {
        width: 100%;
        display: flex;
        padding-top: 40px;
        padding-left: 17%;
        position: sticky;
        top: 50px;
        background-color: #ffffff;

        li {
            margin-right: 40px;
            font-weight: 400;
            line-height: 19px;
            color: #666666;
            position: relative;
            padding: 0 0 8px;
            cursor: pointer;

            img {
                margin-right: 3px;
                vertical-align: bottom;
                margin-bottom: 2px;
            }

            &.active {
                color: #333333;

                &::after {
                    display: inline-block;
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    left: 0;
                    right: 0;
                    background: #0870D1;
                    bottom: 0;
                }
            }
        }
    }

    .result-nums {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #999999;
    }

    .item-name {
        font-weight: bold;
        line-height: 28px;
        color: #0870D1;
        font-size: 20px;
        margin: 0;
    }

    .item-info {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
    }

    .item-time {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
        opacity: 1;
        margin-top: 31px;
    }


    .result-info-div {
        display: flex;
        margin-top: 20px;

        .res-item {
            width: 246px;
            height: 330px;
            background: #FFFFFF;
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            margin-right: 20px;


            .item-img {
                height: 138px;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .item-bottom {
                padding: 10px;

                .item-info {

                }
            }
        }
    }

    .result-list {
        margin-top: 30px;

        li {
            cursor: pointer;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0px;
            }

            .item-info {

            }

            .item-time {
                margin-top: 12px;
            }
        }
    }

    .more-div {
        margin-top: 60px;

        .title {
            font-size: 22px;
            font-weight: bold;
            line-height: 30px;
            color: #333333;
        }

        .table-box {
            margin-top: 30px;

            .table-td {
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 15px;
                }

                cursor: pointer;
                display: inline-block;
                height: 50px;
                background: #F8F8F8;
                opacity: 1;
                border-radius: 25px;
                line-height: 25px;
                padding: 12px 20px;
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .ant-pagination-prev, .ant-pagination-next {

    }
    .img-div{
    width: 98%;
    height: 100vh;
    overflow-y: auto;
    margin-left: 2%;
    .result-nums{
      padding-left: 15.3%;
      padding-bottom: 20px;
      padding-top: 0px;
    }
    .img-item-info{
         display: flex;
         flex-wrap: wrap;
        .img-item{
            width: 255px;
            padding: 4px;
            cursor: pointer;
           img{
               width: 250px;
               height: 182px;
               object-fit: cover;
           }
           .title{
               width: 260px;
               height: 30px;
               font-size: 5px;
               font-family: Microsoft YaHei;
               line-height: 22px;
               opacity: 1;
               font-size: 18px;
               font-weight: bold;
               color: #0870D1;
               margin-right: 30px;
               margin-top: 5px;
           }
         .title-time{
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               font-size: 12px;
           .time{
               width: 130px;
               height: 30px;
               font-size: 12px;
               font-family: Microsoft YaHei;
               color: #9E9E9E;
               opacity: 1;
           }
               .year{
                   color: #9E9E9E;
               }
           }
        }
    }
 }
    .text-info-div{
        width: 100%;
        height: 100vh;
        margin-left: 17%;
        overflow-y: auto;
    .test-div{
        width: 660px;
        height: 100%;
        margin-top: 45px;
        .text-div-li{
        background: #FFFFFF;
        border: 1px solid #DADCE0;
        opacity: 1;
        border-radius: 4px;
        margin-top: 16px;
        height: 160px;

        }
        .text-top{
            margin-left: 23px;
            margin-top: 16px;
            display: flex;
            img{
                width: 16px;
                height: 16px;
            }
            p{
              font-size: 12px;
              color: #3C4043;
              margin-left: 8px;
            }
        }
        .text-middle{
            margin-left: 20px;
            .text-title{
                font-size: 20px;
                font-family: Microsoft YaHei;
                // color: #202124;
                margin-bottom: 4px;
                font-weight: bold;
               color: #0870D1;
               margin-right: 30px;
            }
            .text-content{
              text-overflow: ellipsis;
              overflow: hidden;
             -webkit-line-clamp: 2;
             display: -webkit-box;
             -webkit-box-orient: vertical;
             margin-top: 4px;
             margin-bottom: 4px;
             width: 640px;
             height: 41px;
             font-size: 14px;
             font-family: Microsoft YaHei;
             font-weight: 400;
             line-height: 22px;
             color: #70757A;
             opacity: 1;
            }
            .text-time{
                width: 100px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 22px;
                color: #9E9E9E;
                opacity: 1;
            }
        }
    }
    }

 }

@media (max-width: 840px) {
    .container {
        width: 100%;
        margin: 0 auto;
        padding: 0 30px;
    }
    .search-result-content {
        .item-time {
            margin-top: 24px;
        }

        .result-info-div {
            display: block;

            .res-item {
                width: 100%;
                margin-top: 20px;
                height: auto;

                .item-img {
                    width: 100%;
                    max-height: 120px;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .tab-list li {
            margin-right: 20px;
            font-size: 6px;
        }
    }
}
}
</style>
